import React, { useEffect, useState } from "react";

import ActiveSupply from "./EGcharts/ActiveSupply";
import EgoldSupply from "./EGcharts/EgoldSupply";
import BurntSupply from "./EGcharts/BurntSupply";
import EgoldDistribution from "./EGcharts/EgoldDistribution";
import LiveTokenomics from "./EGcharts/LiveTokenomics";
import OtherSupply from "./EGcharts/OtherSupply";
import PillsMenu from "./Menu/PillsMenu";

import { useWeb3React } from "@web3-react/core";

export default function Stats() {
  const [selectedOption, setSelectedOption] = useState("activesupply");

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  let selectedComponent;

  if (selectedOption === "activesupply") {
    selectedComponent = <ActiveSupply />;
  } else if (selectedOption === "egoldsupply") {
    selectedComponent = <EgoldSupply />;
  } else if (selectedOption === "burntsupply") {
    selectedComponent = <BurntSupply />;
  } else if (selectedOption === "egolddist") {
    selectedComponent = <EgoldDistribution />;
  } else if (selectedOption === "livetok") {
    selectedComponent = <LiveTokenomics />;
  } else if (selectedOption === "othersupply") {
    selectedComponent = <OtherSupply />;
  } else if (selectedOption === "livetok") {
    selectedComponent = <LiveTokenomics />;
  }
  return (
    <>
      <div class="mainsection">
        <div class="minertabsec secpadding lightgrey-bg brrr mb20">
          <PillsMenu activeval="stats" />

          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-stats"
              role="tabpanel"
              aria-labelledby="pills-stats-tab"
              tabindex="0"
            >
              <select
                class="form-select darksec-bg text-white mb20"
                id="selectOption"
                aria-label="Default select example"
                onChange={handleSelectChange}
                value={selectedOption}
              >
                <option value="activesupply" selected>
                主动供应量    
                </option>
                <option value="egoldsupply">Egold 供应量</option>
                <option value="burntsupply">刻录数据</option>
                <option value="egolddist">Egold 分布</option>
                <option value="livetok">实时代币经济学</option>
                <option value="othersupply">非矿业分配</option>
              </select>
              {selectedComponent}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
