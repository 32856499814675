import React from "react";
import { useNavigate } from "react-router-dom";
import { FaShoppingCart, FaUserCog, FaUserPlus } from "react-icons/fa";
import { IoStatsChart } from "react-icons/io5";

export default function StakePillsMenu(props) {
  let navigate = useNavigate();
  return (
    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
         id="pills-live-tab" data-bs-toggle="pill" data-bs-target="#pills-live" type="button" role="tab" aria-controls="pills-live"
          class={
            props.activeval == "live"
              ? "nav-link typetabbtn brltb active "
              : "nav-link typetabbtn brltb"
          }
          aria-selected={props.activeval == "live" ? "true" : "false"}
          onClick={() => {
            navigate("/stake/live");
          }}
        >
          居住
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          id="pills-completed-tab" data-bs-toggle="pill" data-bs-target="#pills-completed" type="button" role="tab" aria-controls="pills-completed"
          class={
            props.activeval == "completed"
              ? "nav-link typetabbtn  active "
              : "nav-link typetabbtn "
          }
          aria-selected={props.activeval == "completed" ? "true" : "false"}
          onClick={() => {
            navigate("/stake/completed");
          }}
        >
          完成
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          id="pills-upcoming-tab" data-bs-toggle="pill" data-bs-target="#pills-upcoming" type="button" role="tab" aria-controls="pills-upcoming"
          class={
            props.activeval == "upcoming"
              ? "nav-link typetabbtn brrtb active "
              : "nav-link typetabbtn brrtb"
          }
          aria-selected={props.activeval == "upcoming" ? "true" : "false"}
          onClick={() => {
            navigate("/stake/upcoming");
          }}
        >
          即将到来的
        </button>
      </li>
    </ul>
  );
}
