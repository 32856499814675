import React, { useState, useEffect, useRef } from "react";
import { useWeb3React } from "@web3-react/core";
import dataVal from "../../../data/Abis.json";
import { Spinner } from "reactstrap";
import LoaderCard from "../../LoaderCard/LoaderCard";
import ConfirmCard from "../../LoaderCard/ConfirmCard";
import ErrorCard from "../../LoaderCard/ErrorCard";

const Web3 = require("web3");

export default function Active(props) {
  const web3 = new Web3(process.env.REACT_APP_RPC);
  const { account, isActive, connector } = useWeb3React();

  const [approveLoader, setapproveLoader] = useState(false);
  const [egoldBalance, setEgoldBalance] = useState("...");
  const [loaderBTN, setloaderBTN] = useState(false);
  const [stakePend, setStakePend] = useState(0);
  const [unstakePend, setUnstakePend] = useState(0);
  const [currentblock, setCurrentBlock] = useState(0);
  const [maxClaimCount, setmaxClaimCount] = useState(10);
  const [tokenapp, setTokenapp] = useState(true);
  const [processState, setprocessState] = useState({
    state: "...",
    data: null,
  });

  useEffect(() => {
    getSummary();
  }, []);

  async function getSummary() {
    const currentbk = await web3.eth.getBlockNumber();
    setCurrentBlock(currentbk);
    const egoldcontractInstance = new web3.eth.Contract(
      dataVal.busdtokenabi,
      dataVal.egoldtokencontract
    );
    await egoldcontractInstance.methods
      .balanceOf(localStorage.getItem("acct"))
      .call({}, function (e, res) {
        setEgoldBalance(noround(parseFloat(web3.utils.fromWei(res)), 5));
      });
  }

  //to not round values while showing balance
  function noround(number, decimalDigits) {
    const powerOfTen = Math.pow(10, decimalDigits);
    const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
    if (Number.isInteger(formattedNumber)) {
      return roundzeroes(formattedNumber, 2);
    } else return formattedNumber;
  }
  //to round decimal points with zeroes
  function roundzeroes(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }

  //To approve EGOLD
  async function approveEGOLD(mid) {
    setapproveLoader(true);
    setprocessState({
      state: "...",
      data: "",
    });
    if (
      Number(
        parseFloat(0.01 *
              (props.tokendata.data.find((x) => x.mid === props.type._id) !=
              undefined
                ? props.tokendata.data.find((x) => x.mid === props.type._id)
                    .pend
                : 0) +
              (props.tokendata.data.find((x) => x.mid === props.type._id) !=
              undefined
                ? props.tokendata.data.find((x) => x.mid === props.type._id)
                    .pend * 0.5
                : 0)
        ).toFixed(5)
      ) >= parseFloat(egoldBalance)
    ) {
      setapproveLoader(false);
      setprocessState({
        state: "error",
        data: (
          <p>
            You have an insufficient amount of EGOLD to process this claim{" "}
            <br />
            Recommended amount of EGOLD to have :{" "}
            {0.01 *
              (props.tokendata.data.find((x) => x.mid === props.type._id) !=
              undefined
                ? props.tokendata.data.find((x) => x.mid === props.type._id)
                    .pend
                : 0) +
              (props.tokendata.data.find((x) => x.mid === props.type._id) !=
              undefined
                ? props.tokendata.data.find((x) => x.mid === props.type._id)
                    .pend * 0.5
                : 0)}{" "}
            EGOLD
          </p>
        ),
      });
    } else {
      const web3 = await new Web3(process.env.REACT_APP_RPC);
      try {
        const farmInstance = new web3.eth.Contract(
          dataVal.farmAbi,
          process.env.REACT_APP_FARMING_ADDR
        );
        await farmInstance.methods
          .pending(0, mid)
          .call()
          .then(async (value) => {
            const web3 = await new Web3(process.env.REACT_APP_RPC);
            const webb3 = new Web3(connector.provider);
            var tokencontractInstance = new webb3.eth.Contract(
              dataVal.busdtokenabi,
              dataVal.egoldtokencontract
            );
            if (
              web3.utils.fromWei(value) / 2 +
              0.01 * (web3.utils.fromWei(value) / 2) <
              0.0001
            ) {
              var appvalue = 0.0001;
            } else {
              var appvalue = noround(
                web3.utils.fromWei(value) / 2 +
                0.02 * (web3.utils.fromWei(value) / 2),
                18
              );
            }

            const estimatedGas = await tokencontractInstance.methods
              .approve(
                process.env.REACT_APP_MINING_ADDR,
                web3.utils.toWei(appvalue.toString())
              )
              .estimateGas({ from: account });

            tokencontractInstance.methods
              .approve(
                process.env.REACT_APP_MINING_ADDR,
                web3.utils.toWei(appvalue.toString())
              )
              .send({
                from: account,
                gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
                gasPrice: await web3.eth.getGasPrice(),
              })
              .on("receipt", async function (res) {
                setTokenapp(false);
                getSummary();
                setapproveLoader(false);
              })
              .on("error", function (error, receipt) {
                setapproveLoader(false);
                checkerror(error);
              });
          });
      } catch (error) {
        setapproveLoader(false);
        console.log("Error-", error);
        checkerror(error);
      }
    }
  }

  async function unstake(mid) {
    const web3 = new Web3(connector.provider);
    setprocessState({ state: "...", data: null });
    setloaderBTN(true);
    const miningcontractInstance = await new web3.eth.Contract(
      dataVal.miningAbi,
      process.env.REACT_APP_MINING_ADDR
    );
    try {
      setprocessState({ state: "processing", data: null });

      const estimatedGas = await miningcontractInstance.methods
        .unstakeNFT(mid)
        .estimateGas({ from: account });

      await miningcontractInstance.methods
        .unstakeNFT(mid)
        .send({
          from: account,
          gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
          gasPrice: await web3.eth.getGasPrice(),
        })
        .on("receipt", async function (res) {
          console.log("resss--", res);
          var x = [];
          if (localStorage.getItem("UnstakePending"))
            x = JSON.parse(localStorage.getItem("UnstakePending"));
          x.push(res.transactionHash);
          localStorage.setItem("UnstakePending", JSON.stringify(x));
          props.setunstakepend(x.length);
          // setUnstakePend(x.length);
          setloaderBTN(false);
          setprocessState({
            state: "done",
            data: res.transactionHash,
          });
        })
        .on("error", function (error, receipt) {
          setloaderBTN(false);
          console.log("error-", error);
          checkerror(error);
        });
    } catch (error) {
      setloaderBTN(false);
      console.log("error-", error);
      checkerror(error);
    }
  }

  function checkerror(err) {
    if (
      err.message ==
      "Please pass numbers as strings or BN objects to avoid precision errors." ||
      err.message.includes(
        "while converting number to string, invalid number value "
      )
    ) {
      setprocessState({
        state: "error",
        data: "请提供有效的输入",
      });
    } else if (JSON.stringify(err.message).includes("transaction underpriced"))
      setprocessState({
        state: "error",
        data: "交易价格被低估。请尝试提高gas价格",
      });
    else
      setprocessState({
        state: "error",
        data: JSON.stringify(err.message),
      });
  }

  return (
    <div>
      <div class="mineraccordian">
        <div class="accordion" id="accordionExample">
          <div class="mineaccordianall">
            <div class="mineaccorsec">
              <div class="minerstate">
                <div class="mineaccorimg">
                  {props.tokendata.data.find((x) => x.mid === props.type._id) !=
                    undefined ? (
                    <img
                      src={require("../../../images/miners/" +
                        props.tokendata.data.find(
                          (x) => x.mid === props.type._id
                        ).mtype +
                        ".png")}
                      alt=""
                      class="img-fluid"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <span class="activebadge">活跃</span>
              </div>
              <div class="mineaccorbtnsec">
                <div class="mineaccorbtns mb15">
                  <a class="mineaccorbtn active">矿工号: {props.type._id}</a>

                  <a class="mineaccorbtn">
                  功率:{" "}
                    {props.tokendata.data.find(
                      (x) => x.mid === props.type._id
                    ) != undefined
                      ? props.tokendata.data.find(
                        (x) => x.mid === props.type._id
                      ).mhash
                      : "..."}{" "}
                    Mhs
                  </a>
                </div>
                <div class="mineaccorbtns">
                  <a class="mineaccorbtn">
                  矿工类型:
                    {props.tokendata.data.find(
                      (x) => x.mid === props.type._id
                    ) != undefined
                      ? props.tokendata.data.find(
                        (x) => x.mid === props.type._id
                      ).mtype
                      : "..."}
                  </a>
                  <a class="mineaccorbtn">
                  已经认领:{" "}
                    {Number(
                      props.tokendata.data.find(
                        (x) => x.mid === props.type._id
                      ) != undefined
                        ? props.tokendata.data.find(
                          (x) => x.mid === props.type._id
                        ).ctr
                        : 0
                    )}
                    /{maxClaimCount}
                  </a>
                </div>
              </div>
            </div>
            <div class="accordion-item mineraccordianitem mineaccoritem">
              <h2 class="accordion-header" id="headingFour">
                <button
                  class="accordion-button collapsed mineraccordianbtn minebtn activeminerbtn"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={"#collapseFour" + props.index}
                  style={{ display: "flex" }}
                  aria-expanded="true"
                  aria-controls="collapseFour"
                >
                  <div
                  className="ntclmdactive"
                    style={{
                      flexBasis: "40%"
                    }}
                  >
                    净认领:{" "}
                    <br className="brmob"/>
                    <span class="greentext">
                      {parseFloat(
                        (parseInt(
                          100 -
                          (51 +
                            (Number(
                              props.tokendata.data.find(
                                (x) => x.mid === props.type._id
                              ) != undefined
                                ? props.tokendata.data.find(
                                  (x) => x.mid === props.type._id
                                ).ctr
                                : 0
                            ) +
                              1) *
                            5 -
                            5)
                        ) /
                          100) *
                        parseFloat(
                          props.tokendata.data.find(
                            (x) => x.mid === props.type._id
                          ) != undefined
                            ? props.tokendata.data.find(
                              (x) => x.mid === props.type._id
                            ).pend
                            : 0
                        )
                      ).toFixed(4)}{" "}
                      EGOLD(V1)
                    </span>
                  </div>
                  <div className="mdetbtns" style={{ display: "flex", gap: 25, flexBasis: "52%",justifyContent:"end" }}>
                    <a
                      class={
                        !tokenapp
                          ? "btn-outline-color-secondary btn-secondary-disabled"
                          : "btn-outline-color-secondary"
                      }
                      style={{ cursor: "pointer" }}
                      onClick={(e) => approveEGOLD(props.type._id)}
                      disabled={!tokenapp}
                    >
                      {approveLoader ? (
                        <Spinner
                          color="black"
                          style={{ height: 16, width: 16 }}
                        />
                      ) : (
                        "通过"
                      )}
                    </a>

                    <button
                      className="btn-color-primary"
                      // class={
                      //   tokenapp
                      //     ? "btn-color-primary btn-disabled"
                      //     : "btn-color-primary"
                      // }
                      style={
                        tokenapp
                          ? { cursor: "not-allowed" }
                          : { cursor: "pointer", border: "unset" }
                      }
                      onClick={(e) =>
                        tokenapp ? null : unstake(props.type._id)
                      }
                      disabled={tokenapp}
                    >
                      {loaderBTN ? (
                        <Spinner
                          color="black"
                          style={{ height: 16, width: 16 }}
                        />
                      ) : (
                        "收集 Egold (V1)"
                      )}
                    </button>
                  </div>
                  {/* <p class="activeminertext">Details</p> */}
                </button>
              </h2>
              <div
                id={"collapseFour" + props.index}
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body mineraccordianbody">
                  <div class="performancehistroy pb-0">
                    <div>
                      <p class="minerownep">已挖矿 (Egold)</p>
                      <p class="minerownep2">
                        {props.tokendata.data.find(
                          (x) => x.mid === props.type._id
                        ) != undefined
                          ? parseFloat(
                            props.tokendata.data.find(
                              (x) => x.mid === props.type._id
                            ).pend
                          ).toFixed(4)
                          : "..."}
                      </p>
                    </div>
                    <div>
                      <p class="minerownep">启动块 </p>
                      <p class="minerownep2 bluetext">
                        {" "}
                        <a
                          target="_blank"
                          style={{ color: "unset" }}
                          href={"https://bscscan.com/block/" + props.type.block}
                        >
                          {props.type.block}
                        </a>
                      </p>
                    </div>
                    <div>
                      <p class="minerownep">开采的区块 </p>
                      <p class="minerownep2">
                        {" "}
                        {currentblock - props.type.block}
                      </p>
                    </div>
                  </div>
                  <div class="performancehistroy">
                    <div>
                      <p class="minerownep">挖矿燃料 (50%)</p>
                      <p class="minerownep2 redtext">
                        {parseFloat(
                          props.tokendata.data.find(
                            (x) => x.mid === props.type._id
                          ) != undefined
                            ? props.tokendata.data.find(
                              (x) => x.mid === props.type._id
                            ).pend * 0.5
                            : 0
                        ).toFixed(4)}{" "}
                        EGOLD
                      </p>
                    </div>
                    <div>
                      <p class="minerownep">
                      折旧 (
                        {Number(
                          props.tokendata.data.find(
                            (x) => x.mid === props.type._id
                          ) != undefined
                            ? props.tokendata.data.find(
                              (x) => x.mid === props.type._id
                            ).ctr
                            : 0
                        ) == 0
                          ? "0"
                          : (Number(
                            props.tokendata.data.find(
                              (x) => x.mid === props.type._id
                            ) != undefined
                              ? props.tokendata.data.find(
                                (x) => x.mid === props.type._id
                              ).ctr
                              : 0
                          ) +
                            1) *
                          5 -
                          5}
                        %)
                      </p>
                      <p class="minerownep2 redtext">
                        {parseFloat(
                          props.tokendata.data.find(
                            (x) => x.mid === props.type._id
                          ) != undefined
                            ? props.tokendata.data.find(
                              (x) => x.mid === props.type._id
                            ).pend *
                            (((Number(
                              props.tokendata.data.find(
                                (x) => x.mid === props.type._id
                              ) != undefined
                                ? props.tokendata.data.find(
                                  (x) => x.mid === props.type._id
                                ).ctr
                                : 0
                            ) +
                              1) *
                              5 -
                              5) /
                              100)
                            : 0
                        ).toFixed(4)}{" "}
                        EGOLD
                      </p>
                    </div>
                    <div>
                      <p class="minerownep">主办 (1%) </p>
                      <p class="minerownep2 redtext">
                        {parseFloat(
                          props.tokendata.data.find(
                            (x) => x.mid === props.type._id
                          ) != undefined
                            ? props.tokendata.data.find(
                              (x) => x.mid === props.type._id
                            ).pend * 0.01
                            : 0
                        ).toFixed(4)}{" "}
                        EGOLD
                      </p>
                    </div>
                  </div>
                  {/* <div class="selectcurbtnsec appactiveminer">
                    <a
                      class={
                        !tokenapp
                          ? "btn-outline-color-secondary btn-secondary-disabled"
                          : "btn-outline-color-secondary"
                      }
                      style={{ cursor: "pointer" }}
                      onClick={(e) => approveEGOLD(props.type._id)}
                      disabled={!tokenapp}
                    >
                      {approveLoader ? (
                        <Spinner
                          color="black"
                          style={{ height: 16, width: 16 }}
                        />
                      ) : (
                        "Approve"
                      )}
                    </a>

                    <button
                      className="btn-color-primary"
                      // class={
                      //   tokenapp
                      //     ? "btn-color-primary btn-disabled"
                      //     : "btn-color-primary"
                      // }
                      style={
                        tokenapp
                          ? { cursor: "not-allowed" }
                          : { cursor: "pointer", border: "unset" }
                      }
                      onClick={(e) =>
                        tokenapp ? null : unstake(props.type._id)
                      }
                      disabled={tokenapp}
                    >
                      {loaderBTN ? (
                        <Spinner
                          color="black"
                          style={{ height: 16, width: 16 }}
                        />
                      ) : (
                        "Collect Egold"
                      )}
                    </button>
                  </div> */}
                  {processState.state == "..." ? (
                    ""
                  ) : processState.state == "processing" ? (
                    <div style={{ margin: 20 }}>
                      <LoaderCard />
                    </div>
                  ) : processState.state == "done" ? (
                    <div style={{ margin: 20 }}>
                      <ConfirmCard tx={processState.data} />
                    </div>
                  ) : (
                    <div style={{ margin: 20 }}>
                      <ErrorCard err={processState.data} />
                    </div>
                  )}
                  {/* {processState.state == "..." ? (
                    ""
                  ) : processState.state == "done" ? (
                    <div
                      class="transictionconfirmsec"
                      style={{
                        padding: 20,
                        borderBottomLeftRadius: 8,
                        borderBottomRightRadius: 8,
                      }}
                    >
                      <p class="text1 mb20">Transaction Confirmed </p>

                      <a
                        href={"https://bscscan.com/tx/" + processState.data}
                        target="_blank"
                        class="btn-outline-grey mb20"
                      >
                        View Transaction
                      </a>
                    </div>
                  ) : (
                    <div class="transictionconfirmsec">
                      <p class="text1 mb20" style={{ color: "red" }}>
                        {processState.data}
                      </p>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
