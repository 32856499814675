import React, { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import { Spinner } from "reactstrap";

const TotaleSupply = () => {
  const [data, setData] = useState([]);

  const CenteredMetric = ({ centerX, centerY }) => {
    return (
      <>
      <text
        x={centerX}
        y={centerY - 15}
        textAnchor="middle"
        dominantBaseline="central"
        fill="black"
        style={{
          fontSize: "18px",
        }}
      >
     其他供应
      </text>
      <text
        x={centerX}
        y={centerY + 12}
        textAnchor="middle"
        dominantBaseline="central"
        fill="black"
        style={{
          fontSize: "18px",
          fontWeight: 700,
        }}
      >
         820 K
      </text>
      </>
     
    );
  };

  useEffect(() => {
    setData([
      {
        id: "开发",
        label: "开发基金",
        value: noround(720000, 2),
        color: "#4F6B75",
      },
      {
        id: "团队",
        label: "团队基金",
        value: noround(77500, 2),
        color: "#FFC727",
      },
      {
        id: "开放流动性",
        label: "开放流动性",
        value: noround(22500, 2),
        color: "#FFE18C",
      },
    ]);
  }, []);

  //to not round values while showing balance
  function noround(val, x) {
    if (!val) return 0;
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }

  return (
    <>
      {data.length == 0 ? (
         <div style={{ padding: "20px", textAlign: "center" }}>
         <Spinner />
       </div>
      ) : (
        <div className="chartbx">
          <div className="chbxhdiv">
            <ResponsivePie
              data={data}
              margin={
                window.innerWidth < 500
                  ? { top: 20, right: 20, bottom: 0, left: 20 }
                  : { top: 40, right: 80, bottom: 40, left: 80 }
              }
              valueFormat=" >-,"
              startAngle={-10}
              sortByValue={false}
              activeOuterRadiusOffset={8}
              enableArcLinkLabels={window.innerWidth < 500 ? false : true}
              enableArcLabels={false}
              arcLinkLabel={(e) => `${e.id}\n(${e.value})`}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor="#ffffff"
              innerRadius={0.45}
              layers={[
                "arcs",
                "arcLabels",
                "arcLinkLabels",
                "legends",
                CenteredMetric,
              ]}
              colors={(slice) => slice.data.color}
            />
          </div>
          <div class="miningcapacity" id="miningCapacitySection">
            <div class="detailCard secpadding mb20">
              <div class="bardelshowp mb10">
                <div class="secondarybardot"></div>开发基金 :{" "}
                {parseFloat(noround(720000, 2)).toLocaleString()}
              </div>
              <div class="bardelshowp mb10">
                <div class="primarybardot"></div>
                团队基金 :{" "}
                {parseFloat(noround(77500, 2)).toLocaleString()}
              </div>
              <div class="bardelshowp mb10">
                <div class="otherbardot"></div>
                开放流动性 :{" "}
                {parseFloat(noround(22500, 2)).toLocaleString()}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default TotaleSupply;
