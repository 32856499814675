import React, { useState, useEffect, useRef } from "react";
import { useWeb3React } from "@web3-react/core";
import dataVal from "../../../data/Abis.json";
import { Spinner } from "reactstrap";
import MinerInfo from "./MinerInfoInactive";
import PillsMenu from "../../Menu/PillsMenu";
import MinePillsMenu from "../MinePillsMenu";
import WalletButton from "../../Buttons/WalletButton/WalletButton";
import PendingCheck from "../PendingCheck";
import ConvertCard from "../../EGConverter/ConvertCard";

const Web3 = require("web3");

export default function Inactive() {
  const { account, isActive, connector } = useWeb3React();
  const CLAIM_COUNT_ABI = dataVal.ctrAbi;
  const CLAIM_COUNTER_CONTRACT_ADDRESS = process.env.REACT_APP_CLAIM_CTR;
  const NFT_ABI = dataVal.nftAbi;
  const FARMING_ABI = dataVal.farmAbi;
  const NFT_ADDRESS = process.env.REACT_APP_NFT_ADDR;
  const web3 = new Web3(process.env.REACT_APP_RPC);
  const nftInstance = new web3.eth.Contract(NFT_ABI, NFT_ADDRESS);
  const FARMING_ADDRESS = process.env.REACT_APP_FARMING_ADDR;
  const farmInstance = new web3.eth.Contract(FARMING_ABI, FARMING_ADDRESS);

  const [summaryData, setSummaryData] = useState({ data: null });
  const [tokendata, setTokendata] = useState({ data: null, loading: true });
  const [maxClaimCount, setmaxClaimCount] = useState(10);
  const [junkCount, setJunkCount] = useState(0);
  const [loader, setLoader] = useState(true);
  const [minerOwned, setMinersOwned] = useState("...");
  const [stakedCount, setStakedCount] = useState(0);
  const [pendingStatus, setpendingStatus] = useState(0);
  const [totalInactiveMhash, setInactiveMhash] = useState(0);
  const [stakedMhash, setStakedMhash] = useState(0);
  const [consumedHash, setconsumedHash] = useState(0);
  const [totalpending, setTotalpending] = useState(0);
  const [minestatData, setMineStatData] = useState({
    data: null,
    loading: true,
  });
  const miners = [5, 6, 7, 8, 9, 2, 3, 4];

  var jcount = 0;

  useEffect(() => {
    getSummary();
    localStorage.setItem("junkcount", 0);
    getData();
  }, []);

  function getAll() {
    getSummary();
    getData();
  }

  const getData = async () => {
    const url2 =
      "https://egold-miner.tagdev.info/miner/" + localStorage.getItem("acct");

    var stakedhash = 0;
    var pending = 0;

    fetch(url2)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        if (Array.isArray(newData)) {
          setStakedCount(newData.length);
          for (let i = 0; i < newData.length; i++) {
            nftInstance.methods
              .fetchMinerInfo(newData[i]._id)
              .call({}, async function (e, ress) {
                stakedhash += parseInt(ress[2]);

                setStakedMhash(parseInt(stakedhash));
              });
            farmInstance.methods
              .pending(0, newData[i]._id)
              .call({}, async function (e, res) {
                pending =
                  parseFloat(pending) + parseFloat(web3.utils.fromWei(res));
                setTotalpending(pending);
              });
          }
        }
      });

    const minerurl =
      "https://egold-treasury.tagdev.info/v1/summary/minersOf/" +
      localStorage.getItem("acct");

    var sum = 0;
    var sumRes = 0;
    const counterContractInstance = await new web3.eth.Contract(
      CLAIM_COUNT_ABI,
      CLAIM_COUNTER_CONTRACT_ADDRESS
    );

    fetch(minerurl)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        if (Array.isArray(newData)) {
          setMinersOwned(newData.length);
          for (let i = 0; i < newData.length; i++) {
            counterContractInstance.methods
              .fetchCtr(newData[i]._id)
              .call(function (err, res) {
                if (err) {
                  console.log("An error occured", err);
                  return;
                }
                let currntObj = {};
                currntObj[newData[i]._id] = res;
                if (parseInt(res) >= 10) {
                  nftInstance.methods
                    .fetchMinerInfo(newData[i]._id)
                    .call({}, async function (e, ress) {
                      sumRes += parseInt(ress[2]);
                      setconsumedHash(parseInt(sumRes));
                    });
                } else {
                  nftInstance.methods
                    .fetchMinerInfo(newData[i]._id)
                    .call({}, async function (e, ress) {
                      sum += parseInt(ress[2]);
                      setInactiveMhash(parseInt(sum));
                    });
                }
              });
          }
        }
      });
    const minestaturl =
      "https://egold-miner.tagdev.info/mininginfo/" +
      localStorage.getItem("acct");

    var sum = 0;

    fetch(minestaturl)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setMineStatData({ data: newData, loading: false });
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
  };

  function setStakeFN(len) {
    setpendingStatus(len);
  }
  useEffect(() => {
    var ttokendets = [];
    if (summaryData.data != null) {
      summaryData.data.map((element) => {
        console.log("junksountsummary", summaryData.data);
        getCurrentCtr(element._id, ttokendets);
      });
    }
  }, [summaryData.data]);

  const getSummary = async () => {
    setTokendata({ data: null, loading: true });
    const web3 = await new Web3(process.env.REACT_APP_RPC);
    const url =
      "https://egold-treasury.tagdev.info/v1/summary/minersOf/" +
      localStorage.getItem("acct");

    fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(async function (newData) {
        if (newData.length > 0) {
          console.log("summary data", newData);
          setSummaryData({ data: newData });
        } else {
          setSummaryData({ data: null });
          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
  };

  const getCurrentCtr = async (id, ttokendets) => {
    console.log("junksount!!", jcount, id);
    const web3 = await new Web3(process.env.REACT_APP_RPC);
    const counterContractInstance = await new web3.eth.Contract(
      CLAIM_COUNT_ABI,
      CLAIM_COUNTER_CONTRACT_ADDRESS
    );
    await counterContractInstance.methods
      .fetchCtr(id)
      .call(function (err, res) {
        if (err) {
          console.log("An error occured", err);
          return;
        }
        var tokctr = res;
        let tokdets = { mid: id, ctr: tokctr };
        ttokendets.push(tokdets);
        if (Number(tokdets.ctr) >= maxClaimCount) {
          jcount = jcount + 1;
          setJunkCount(jcount);
          console.log("junksount", jcount, id);
          localStorage.setItem("junkcount", jcount);
        }
        setTokendata({ data: ttokendets, loading: false });
      });
  };

  return (
    <div
      class="mainsection"
      style={{
        position: "relative",
      }}
    >
      <div class="minertabsec secpadding lightgrey-bg brrr mb20">
        <PillsMenu activeval="mine" />
        <div>
          <ConvertCard />
        </div>
        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="pills-trade"
            role="tabpanel"
            aria-labelledby="pills-trade-tab"
            tabindex="0"
          >
            <div class="wallettabsec egoldsubtabsec lightgrey-bg brtlr">
              <div class="walletamtsec mb20">
                <div class="minerownedsec">
                  <div class="row">
                    <div class="col-6 d-flex align-items-center">
                      <p class="minersownedp">矿工拥有者</p>
                    </div>
                    <div class="col-6">
                      <p class="minersownedp text-end">
                        {minerOwned != "..."
                          ? parseInt(minerOwned) + stakedCount
                          : "..."}
                        /25 ( {totalInactiveMhash + stakedMhash + consumedHash}{" "}
                        Mhs)
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div class="minerownedpsec">
                    <div>
                      <p class="minerownep">已挖矿 (Egold)</p>
                      <p class="minerownep2">
                        {minestatData.loading === true
                          ? "...."
                          : minestatData.data.egold_mined
                          ? parseFloat(
                              minestatData.data.egold_mined + totalpending
                            ).toFixed(5)
                          : parseFloat(0).toFixed(5)}
                      </p>
                    </div>
                    <div>
                      <p class="minerownep">已收集 (Egold) </p>
                      <p class="minerownep2">
                        {minestatData.loading === true
                          ? "...."
                          : minestatData.data.egold_mined
                          ? parseFloat(minestatData.data.egold_mined).toFixed(5)
                          : parseFloat(0).toFixed(5)}
                      </p>
                    </div>
                    <div>
                      <p class="minerownep">待定 (Egold) </p>
                      <p class="minerownep2">
                        {" "}
                        {parseFloat(totalpending).toFixed(5)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <MinePillsMenu
                activeval="inactive"
                inactivecount={minerOwned}
                activecount={stakedCount}
              />
              {loader === true && tokendata.loading === true ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Spinner color="black" size={22} />
                </div>
              ) : (
                <>
                  <PendingCheck
                    status={pendingStatus}
                    summaryfetch={getAll}
                    setpend={setStakeFN}
                  />
                  {summaryData.data == null ||
                  summaryData.data.length - junkCount == 0 ? (
                    <span
                      style={{
                        backgroundColor: "rgb(79 107 117)",
                        textAlign: "center",
                        padding: "10px",
                        borderRadius: "10px",
                        margin: "2px 20px",
                        display: "block",
                        fontSize: "14px",
                        color: "white",
                        textTransform: "uppercase",
                      }}
                    >
                      没有不活跃的矿工
                    </span>
                  ) : (
                    summaryData.data.map(function (element, index) {
                      return parseInt(
                        tokendata.data.find((x) => x.mid === element._id) !=
                          undefined
                          ? tokendata.data.find((x) => x.mid === element._id)
                              .ctr
                          : 0
                      ) < maxClaimCount ? (
                        <MinerInfo
                          type={element}
                          tokendata={tokendata}
                          index={index}
                          setpend={setStakeFN}
                        />
                      ) : (
                        ""
                      );
                    })
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        id="overlay"
        style={
          localStorage.getItem("acct") || isActive ? { display: "none" } : {}
        }
      >
        <div className="connectinoverlay">
          {" "}
          <WalletButton />
        </div>
        请连接您的钱包以继续
      </div>
    </div>
  );
}
