import React from "react";
import "./index.css";
import { hooks as metaMaskHooks, metaMask } from "./connectors/metaMask";
import {
  coinbaseWallet,
  hooks as coinbaseWalletHooks,
} from "./connectors/coinbaseWallet";
import {
  hooks as walletConnectV2Hooks,
  walletConnectV2,
} from "./connectors/walletConnect";
import { Web3ReactProvider } from "@web3-react/core";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { StrictMode } from "react";
import ErrorPage from "./components/ErrorPage";
import Header from "./components/Header/Header";
import HeaderNoCard from "./components/Header/HeaderNoCard";
import HeaderNoWallet from "./components/Header/HeaderNoWallet";
import Footer from "./components/Footer/Footer";
import AppMiningCalc from "./components/AppMiningCalc";
import Transfer from "./components/AppTransfer";
import AppExref from "./components/AppExref";
import Wallet from "./components/Wallet/wallet";
import Send from "./components/Wallet/send";
import Receive from "./components/Wallet/receive";
import SelectToken from "./components/Wallet/SelectToken";
import Stats from "./components/AppStats";

// Trade
import Swap from "./components/AppTradeSwap";
import LiquidityV1 from "./components/AppTradeLiquidityv1";
import LiquidityV2 from "./components/AppTradeLiquidityv2";
import LPFarm from "./components/AppTradeFarm";

//Mine
import Inactive from "./components/Mine/Inactive/Inactive"
import Active from "./components/Mine/Active/Active"
import Junk from "./components/Mine/Junkyard/Junkyard"

//STAKE
import StakeLive from "./components/AppStakeLive";
import StakeCompleted from "./components/AppStakeCompleted";
import StakeUpcoming from "./components/AppStakeUpcoming";

// To disable log on production
if (process.env.NODE_ENV === "production") {
  console.log = () => { };
  console.error = () => { };
  console.debug = () => { };
}

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <Header />
          <Swap />
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/trade",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <Header />
          <Swap />
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/trade/swap",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <Header />
          <Swap />
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/trade/liquidity",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <Header />
          <LiquidityV2 />
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/trade/liquidityV1",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <Header />
          <LiquidityV1 />
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/trade/lpfarm",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <Header />
          <LPFarm />
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/mine",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <Header />
          <Inactive />
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/mine/inactive",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <Header />
          <Inactive />
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/mine/active",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <Header />
          <Active />
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/mine/junk",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <Header />
          <Junk />
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  
  {
    path: "/stake",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <Header />
          <StakeLive />
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/stake/live",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <Header />
          <StakeLive />
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },

  {
    path: "/stake/completed",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <Header />
          <StakeCompleted />
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/stake/upcoming",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <Header />
          <StakeUpcoming />
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/selecttoken",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <HeaderNoWallet />
          <SelectToken />
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },

  {
    path: "/exref/:id",
    element: <AppExref />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/wallet",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <HeaderNoWallet />
          <Wallet />
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/send",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <HeaderNoWallet />
          <Send />
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/receive",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <HeaderNoWallet />
          <Receive />
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/transfer/:id",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <Header />
          <Transfer />
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/stats",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <Header />
          <Stats />
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/calculator",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <Header />
          <AppMiningCalc />
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
]);

const connectors = [
  [metaMask, metaMaskHooks],
  [walletConnectV2, walletConnectV2Hooks],
  [coinbaseWallet, coinbaseWalletHooks],
];

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const isSiteDown = false;

root.render(
  <StrictMode>
    <Web3ReactProvider connectors={connectors}>
      <div className="content">
        <div className="wrapper">
          <RouterProvider router={router} />
        </div>
      </div>
    </Web3ReactProvider>
  </StrictMode>
);
