import React, { useEffect, useState, useLayoutEffect } from "react";
import PillsMenu from "./Menu/PillsMenu";
import StakePillsMenu from "./Stake/StakePillsMenu";
import StakeCard from "./Stake/StakeCard";
import WalletButton from "./Buttons/WalletButton/WalletButton";
import { useWeb3React } from "@web3-react/core";
import dataVal from "../data/Abis.json";
import { Spinner } from "reactstrap";

const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);

export default function Swap() {
  const { account, isActive, connector } = useWeb3React();
  const [Listitems, setListitems] = useState({ data: [], status: false });
  const [CMSdata, setCMSData] = useState({ data: [], status: false });
  const [priceInfo, setPriceInfo] = useState({ data: null, status: false });
  const [dailystate, setDailystate] = useState({ data: null, status: false });
  const [dropSelect, setdropSelect] = useState("all");

  let x = {};
  // let counter = "...";

  useLayoutEffect(() => {
    getSummary();
    fetchData();
  }, []);

  useEffect(() => {
    getSummary();
  }, [account]);

  const [counter, setCounter] = useState(null);

  useLayoutEffect(() => {
    // Initialize the counter and fetch data
    if (Listitems.status && CMSdata.status && priceInfo.status && dailystate.status) {
      const liveProjectsCount = Listitems.data.filter((dataitem) => {
        const x = CMSdata.data.data.find(
          (obj) => obj.attributes.farmAddress === dataitem.id
        );
        return x !== undefined ;
      }).length;
      console.log("List itemss---", Listitems,CMSdata, liveProjectsCount);
      setCounter(liveProjectsCount);
    }
  }, [Listitems, CMSdata, priceInfo, dailystate]);

  const getSummary = async () => {
    const url =
      "https://seashell-app-tpow8.ondigitalocean.app/api/projects?populate=icon";
    fetch(url, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer " +
          "f708510f4b92b800e8e7a68cb9926e492f713bc9159992037cc521d4a5ff4b3b0f7e70bd0819cbdcf329970229bdb9d67a0500568864f89f4aae596d2b1d9536c693c8b7defb4ee452225796aed78277e5176fda17d4f60cb2ac814214040a684554f8466ad8c7857a762e8ada3c84a8a030139b63f369f304898ae6605e5ebf",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(
        (data) => setCMSData({ data: data, status: true }) + getpriceInfo(data)
      )
      .catch((error) => console.error(error));
  };

  function getpriceInfo(data) {
    let priceInfo = {};

    const fetchPromises = data.data.map(async function (items) {
      const urlprice =
        "https://egold-marketdata.herokuapp.com/v1/summary/getTokenPrices/" +
        items.attributes.tokensAddress;

      fetch(urlprice)
        .then(function (response) {
          return response.json();
        })
        .then(function (prices) {
          if ("price" in prices) {
            let keyp = items.attributes.farmAddress;
            let pricep = prices.price;
            priceInfo = Object.assign(priceInfo, { [keyp]: pricep });
          }
        });
    });

    Promise.all(fetchPromises)
      .then(() => {
        setPriceInfo({ data: priceInfo, status: true });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    const url24 =
      "https://egold-marketdata.herokuapp.com/v1/summary/getLatestTrades/1/";
    //fetch 24 hour market data
    fetch(url24)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        console.log("TAAGG EGOLD PRICEEE", newData);
        setDailystate({ data: newData, status: true });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function setDataformat(
    name,
    index,
    apiData,
    endBres,
    resTKO,
    resEgold,
    rwsblk
  ) {
    let data = {
      id: apiData._id,
      tokname: name,
      tokens: parseFloat(web3.utils.fromWei(resTKO)).toFixed(2),
      participants: apiData.participants,
      egoldstkd: parseFloat(web3.utils.fromWei(resEgold)).toFixed(8),
      apy: "25.76",
      endblk: endBres,
      rwsblk: web3.utils.fromWei(rwsblk),
    };
    return data;
  }

  async function fetchData() {
    const url = "https://egold-staking-transactions.herokuapp.com/getallfarms";
    let previousList = [];
    const egoldcontractInstance = await new web3.eth.Contract(
      dataVal.tokenabi,
      process.env.REACT_APP_EGOLD_ADDR
    );
    var count = null;
    fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(function (farmdata) {
        farmdata.data.length > 0
          ? (count = farmdata.data.some((item) => item.active === true)) + count
            ? farmdata.data.map(async (items, index) => {
                if (items.active == true) {
                  const farmContractInstance = await new web3.eth.Contract(
                    dataVal.stakefarmabi,
                    items._id
                  );
                  await farmContractInstance.methods
                    .erc20()
                    .call(async function (err, res1) {
                      const farmContractNameInstance =
                        await new web3.eth.Contract(dataVal.stakenameabi, res1);
                      await farmContractNameInstance.methods
                        .name()
                        .call(async function (err, res) {
                          await farmContractInstance.methods
                            .endBlock()
                            .call(async function (err, endBres) {
                              await farmContractInstance.methods
                                .rewardPerBlock()
                                .call(async function (err, rwsblk) {
                                  await farmContractNameInstance.methods
                                    .balanceOf(items._id)
                                    .call(async function (err, resTKO) {
                                      await egoldcontractInstance.methods
                                        .balanceOf(items._id)
                                        .call({}, function (e, resEgold) {
                                          let formattedData = setDataformat(
                                            res,
                                            index,
                                            items,
                                            endBres,
                                            resTKO,
                                            resEgold,
                                            rwsblk
                                          );
                                          console.log("List itemsszz---", previousList);
                                          previousList.push(formattedData);
                                          setListitems({
                                            data: previousList,
                                            status: true,
                                          });
                                        });
                                    });
                                });
                            });
                        });
                    });
                }
              })
            : setListitems({ data: [], status: true })
          : setListitems({ data: [], status: true });
      })

      .catch(function (error) {
        console.log("Requestfailed", error);
      });
  }

  //to not round values while showing balance
  function noround(number, decimalDigits) {
    const powerOfTen = Math.pow(10, decimalDigits);
    const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
    if (Number.isInteger(formattedNumber)) {
      return roundzeroes(formattedNumber, 2);
    } else return formattedNumber;
  }
  //to round decimal points with zeroes
  function roundzeroes(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      var x = noround(num, 2);
      return x; // if value < 1000, nothing to do
    }
  }

  return (
    <>
      <div
        class="mainsection"
        style={{
          position: "relative",
        }}
      >
        <div class="minertabsec secpadding lightgrey-bg brrr mb20">
          <PillsMenu activeval="stake" />
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-trade"
              role="tabpanel"
              aria-labelledby="pills-trade-tab"
              tabindex="0"
            >
              <div class="wallettabsec egoldsubtabsec lightgrey-bg brtlr">
                <StakePillsMenu activeval="live" />
                <div>
                  <select
                    class="form-select darksec-bg text-white mb20"
                    onChange={(e) => {
                      setdropSelect(e.target.value);
                    }}
                    aria-label="Default select example"
                    selected={dropSelect}
                  >
                    <option value="all">所有权益质押农场</option>
                    <option value="own">我的质押农场</option>
                  </select>
                </div>
                <div class="tab-content" id="pills-tabContent">
                  {/* <div
                    className="tab-pane fade show active"
                    id="pills-swap"
                    role="tabpanel"
                    aria-labelledby="pills-swap-tab"
                    tabIndex="0"
                  >
                    {Listitems.status && CMSdata.status ? (
                      Listitems.data.length !== 0 ? (
                        (() => {
                          let counter = 0; // Initialize counter 
                          const liveProjects = Listitems.data
                            .sort((a, b) => b.startBlock - a.startBlock)
                            .map((dataitem, index) => {
                              const x = CMSdata.data.data.find(
                                (obj) =>
                                  obj.attributes.farmAddress === dataitem.id
                              );
                              if (
                                x !== undefined &&
                                priceInfo.status &&
                                dailystate.status
                              ) {
                                counter = counter + 1;
                                return (
                                  <StakeCard
                                    itemdata={dataitem}
                                    cmsdata={x}
                                    priceinfo={priceInfo}
                                    dailystate={dailystate}
                                    type="live"
                                    selected={dropSelect}
                                    key={dataitem.id}
                                  />
                                );
                              }
                              return null;
                            });
                          return (
                            <div>
                              {counter === 0 && (
                                <div className="alertnotification">
                                  <p className="alertnotificationp">
                                    No live projects available
                                  </p>
                                </div>
                              )}
                              {liveProjects}
                            </div>
                          );
                        })()
                      ) : (
                        <div className="alertnotification">
                          <p className="alertnotificationp">
                            No live projects available
                          </p>
                        </div>
                      )
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Spinner color="black" size={20} />
                      </div>
                    )}
                  </div> */}
                  <div
                    className="tab-pane fade show active"
                    id="pills-swap"
                    role="tabpanel"
                    aria-labelledby="pills-swap-tab"
                    tabIndex="0"
                  >
                    {counter === null ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Spinner color="black" size={20} />
                      </div>
                    ) : counter === 0 ? (
                      <p className="warninfobox">没有可用的实时项目</p>
                    ) : (
                      Listitems.data
                        .filter((dataitem) => {
                          const x = CMSdata.data.data.find(
                            (obj) => obj.attributes.farmAddress === dataitem.id
                          );
                          return (
                            x !== undefined &&
                            priceInfo.status &&
                            dailystate.status
                          );
                        })
                        .sort((a, b) => b.startBlock - a.startBlock)
                        .map((dataitem) => (
                          <StakeCard
                            key={dataitem.id}
                            itemdata={dataitem}
                            cmsdata={CMSdata.data.data.find(
                              (obj) =>
                                obj.attributes.farmAddress === dataitem.id
                            )}
                            priceinfo={priceInfo}
                            dailystate={dailystate}
                            selected={dropSelect}
                            type="live"
                          />
                        ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="overlay"
          style={
            localStorage.getItem("acct") || isActive ? { display: "none" } : {}
          }
        >
          <div className="connectinoverlay">
            {" "}
            <WalletButton />
          </div>
          请连接您的钱包以继续
        </div>
      </div>
    </>
  );
}
