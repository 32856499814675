import React, { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { Spinner } from "reactstrap";
import LoaderCard from "../../../LoaderCard/LoaderCard";
import ConfirmCard from "../../../LoaderCard/ConfirmCard";
import ErrorCard from "../../../LoaderCard/ErrorCard";
import dataVal from "../../../../data/Abis.json";

export default function LiquidityRemoveWidget(props) {
  const { account, isActive, connector } = useWeb3React();

  const Web3 = require("web3");
  const web3 = new Web3(process.env.REACT_APP_RPC);

  const [removeLQ, setremoveLQ] = useState(true);
  const [liquidityTokenBlnce, setliquidityTokenBlnce] = useState(null);
  const [LPTremoveVal, setTLPTremoveVal] = useState(null);
  const [liquidityContract, setliquidityContract] = useState("...");
  const [processState, setprocessState] = useState({
    state: "...",
    data: null,
  });
  const [remapprove, setRemapprove] = useState(false);

  useEffect(() => {
    if (isActive) {
      fetchLQBalance();
    } else {
      setliquidityTokenBlnce(0);
    }
  }, [account]);

  const fetchLQBalance = async () => {
    if (localStorage.getItem("acct") && account) {
      const web3 = new Web3(process.env.REACT_APP_RPC);
      const pancakeFactorycontractInstance = await new web3.eth.Contract(
        dataVal.pcfactoryabi,
        process.env.REACT_APP_PANCAKE_FACTORY_ADDR
      );
      await pancakeFactorycontractInstance.methods
        .getPair(
          process.env.REACT_APP_USDT_ADDR,
          process.env.REACT_APP_EGOLDV2_ADDR
        )
        .call({}, function (e, res) {
          setliquidityContract(res);
          const LQInstance = new web3.eth.Contract(dataVal.tokenabi, res);
          LQInstance.methods
            .balanceOf(account)
            .call({}, function (e, resbalance) {
              setliquidityTokenBlnce(
                noround(parseFloat(web3.utils.fromWei(resbalance)), 4)
              );
            });
        });
    }
  };
  function checkerror(err) {
    if (
      err.message ==
      "Please pass numbers as strings or BN objects to avoid precision errors." || err.message.includes("while converting number to string, invalid number value ")
    ) {
      setprocessState({
        state: "error",
        data: "请提供有效的输入",
      });
    } else if (JSON.stringify(err.message).includes("transaction underpriced"))
      setprocessState({
        state: "error",
        data: "交易价格被低估。请尝试提高gas价格",
      });
    else
      setprocessState({
        state: "error",
        data: JSON.stringify(err.message),
      });
  }


  const approveremoval = async () => {
    if (localStorage.getItem("acct")) {
      try {
        if (Number(LPTremoveVal) > Number(liquidityTokenBlnce)) {
          setprocessState({ state: "error", data : "余额不足" });
        } else {
          setprocessState({ state: "...", data: null });
          const webb3 = new Web3(connector.provider);
          setremoveLQ(true);
          setRemapprove(true);
          const removalapproveInstance = await new webb3.eth.Contract(
            dataVal.tokenabi,
            liquidityContract
          );


        const estimatedGas = await removalapproveInstance.methods
        .approve(
          process.env.REACT_APP_PANCAKE_ROUTER_ADDR,
          webb3.utils.toWei(LPTremoveVal.toString())
        )
        .estimateGas({ from: account });

          await removalapproveInstance.methods
            .approve(
              process.env.REACT_APP_PANCAKE_ROUTER_ADDR,
              webb3.utils.toWei(LPTremoveVal.toString())
            )
            .send({
              from: account,
              gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
              gasPrice: await web3.eth.getGasPrice(),
            })
            .on("receipt", async function (res) {
              setremoveLQ(false);
              setRemapprove(false);
            })
            .on("error", function (error, receipt) {
              checkerror(error);
              console.log("error", error);
            });
        }
      } catch (e) {
        setRemapprove(false);
        console.log(e);
        checkerror(e);
      }
    }
  };

  const removeLiquidity = async () => {
    if (localStorage.getItem("acct")) {
      const web3 = new Web3(connector.provider);
      const removeLiquidityInstance = new web3.eth.Contract(
        dataVal.pcrouterabi,
        process.env.REACT_APP_PANCAKE_ROUTER_ADDR
      );

      var today = new Date();
      var futureDate = new Date(today.getTime() + 60000);
      var LQamnt = String(LPTremoveVal);
      var Zamnt = String(0.0);

      try {
        setprocessState({ state: "processing", data: null });

        const estimatedGas = await removeLiquidityInstance.methods
        .removeLiquidity(
          process.env.REACT_APP_USDT_ADDR,
          process.env.REACT_APP_EGOLDV2_ADDR,
          web3.utils.toWei(LQamnt.toString()),
          Zamnt,
          Zamnt,
          account,
          futureDate.getTime()
        )
          .estimateGas({ from: account });

        await removeLiquidityInstance.methods
          .removeLiquidity(
            process.env.REACT_APP_USDT_ADDR,
            process.env.REACT_APP_EGOLDV2_ADDR,
            web3.utils.toWei(LQamnt.toString()),
            Zamnt,
            Zamnt,
            account,
            futureDate.getTime()
          )
          .send({
            from: account,
            gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
            gasPrice: await web3.eth.getGasPrice(),
          })
          .on("receipt", async function (res) {
            setremoveLQ(true);
            fetchLQBalance();
            setprocessState({ state: "done", data: res.transactionHash });
          })
          .on("error", function (error, receipt) {
            checkerror(error);
          });
      } catch (e) {
        console.log(e);
        checkerror(e)
      }
    }
  };

  const getLPTval = async (e) => {
    setTLPTremoveVal(e);
  };
  const getMaxLPT = () => {
    setTLPTremoveVal(liquidityTokenBlnce);
  };

  //to not round values while showing balance
  function noround(number, decimalDigits) {
    const powerOfTen = Math.pow(10, decimalDigits);
    const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
    if (Number.isInteger(formattedNumber)) {
      return roundzeroes(formattedNumber, 2);
    } else return formattedNumber;
  }
  //to round decimal points with zeroes
  function roundzeroes(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      var x = noround(num, 2);
      return x; // if value < 1000, nothing to do
    }
  }

  return (
    <div class="detailCard secpadding mb20">
      <div class="fromreactangle p15 mb15">
            <p class="fs12 mb10">数量</p>
        <p class="fromreactp">
          <input
            className="swapinput"
            placeholder="0.00"
            value={LPTremoveVal}
            onChange={(e) => getLPTval(e.target.value)}
          />{" "}
          <span
            class="float-right fromreactspan"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "3px",
            }}
          >
            <span className="egoldicon" style={{ height: "26px" }}></span>
            LPT{" "}
          </span>
        </p>
        <p class="fs12" style={{marginTop:"5px"}}>
        平衡: {liquidityTokenBlnce}{" "}
          <span
            class="float-right yellowtext fs14"
            style={{
              cursor: "pointer",
            }}
            onClick={(e) => getMaxLPT()}
          >
            最大使用
          </span>
        </p>
      </div>
      <button
        type="button"
        className="btn-color-primary mb10"
        onClick={(e) => approveremoval()}
        style={{ width: "100%", border: "0px" }}
        disabled={!removeLQ}
      >
        {remapprove ? (
          <Spinner size="sm" />
        ) : (
          <span>
            通过 <br className="mobbr" />
            移动
          </span>
        )}
      </button>
      <button
        type="button"
        className="btn-color-primary"
        style={{ width: "100%", border: "0px" }}
        onClick={(e) => removeLiquidity()}
        disabled={removeLQ}
      >
        消除流动性
      </button>
      {processState.state == "..." ? (
        ""
      ) : processState.state == "processing" ? (
        <LoaderCard />
      ) : processState.state == "done" ? (
        <ConfirmCard tx={processState.data} />
      ) : (
        <ErrorCard err={processState.data} />
      )}
    </div>
  );
}
