import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaShoppingCart, FaUserCog, FaUserPlus } from "react-icons/fa";
import { IoStatsChart } from "react-icons/io5";
import { Spinner } from "reactstrap";

export default function LiquidityData(props) {
  let navigate = useNavigate();
  const [farmData, setfarmData] = useState({ data: null, loading: true });
  const [activelatest, setactivelatest] = useState(true);
  const [marketData, setmarketData] = useState([]);


  const latestfarmDetails = () => {
    setfarmData({ loading: true });
    setactivelatest(true);
    const farmurl =
      "https://egold-marketdata.herokuapp.com/v1/summary/getFarm/20";
    //fetch Farm data
    fetch(farmurl)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setfarmData({ data: newData, loading: false });
      })
      .catch(function (error) {
        console.log(error);
      });

  };
  useEffect(() => {
    latestfarmDetails();
    getLptprice()
  }, []);

  function getLptprice() {
    const marketurl = "https://egold-marketdata.herokuapp.com/v1/summary/";
    fetch(marketurl)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData2) {
        setmarketData(newData2);
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const userfarmDetails = () => {
    setactivelatest(false);
    setfarmData({ loading: true });
    const farmurlper =
      "https://egold-marketdata.herokuapp.com/v1/summary/getFarm/20/" +
      localStorage.getItem("acct");
    //fetch Farm data
    fetch(farmurlper)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setfarmData({ data: newData, loading: false });
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  return (
    <div class="detailCard secpadding mb20">
      <div class="wallettabsec latesttradesec brtlr">
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button className={activelatest ? "nav-link typetabbtn brltb active " : "nav-link typetabbtn brltb"} id="pills-lateststake-tab" data-bs-toggle="pill" data-bs-target="#pills-lateststake" type="button" role="tab" aria-controls="pills-lateststake" aria-selected="true" onClick={(e) => latestfarmDetails()}>最新股份</button>
          </li>
          <li class="nav-item" role="presentation">
            <button className={activelatest ? "nav-link typetabbtn brrtb " : "nav-link typetabbtn brrtb active"} id="pills-yourstakes-tab" data-bs-toggle="pill" data-bs-target="#pills-yourstakes" type="button" role="tab" aria-controls="pills-yourstakes" aria-selected="false" onClick={(e) => userfarmDetails()}>你的赌注</button>
          </li>
        </ul>
        {farmData.loading ? (
          <div style={{ justifyContent: "center", display: "flex" }}>
            <Spinner color="black" size={22} />
          </div>) :
          farmData.data.length < 1 ? (
            <span>没有农场数据</span>
          ) :
            <>
              <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-lateststake" role="tabpanel" aria-labelledby="pills-lateststake-tab" tabindex="0">
                  <div class="latesttradetable table-responsive">
                    <table class="table table-bordered text-center">
                      <thead>
                        <tr class="darktrbg text-white">
                          <th>数量(LPT)</th>
                          <th>数量 (USD)</th>
                          <th>交易哈希值</th>
                        </tr>
                      </thead>
                      <tbody>

                        {
                          Object.keys(farmData.data).map((k, i) => {
                            let data = farmData.data;
                            return data[i].type == "add" ? (
                              // <li>
                              //   <span className="tablelimegreen ">{data[i].pool}</span>
                              //   <label className="tablelimegreen tcenter">
                              //     {parseFloat(data[i].amount).toFixed(4)}
                              //   </label>
                              //   <span className="tablelimegreen thirdcolright">
                              //     <a
                              //       target="_blank"
                              //       rel="noopener noreferrer"
                              //       href={"https://bscscan.com/tx/" + data[i].txn}
                              //     >
                              //       {String(data[i].txn).slice(0, 8)}
                              //     </a>
                              //   </span>
                              // </li>
                              <tr class="lightgreenbg">
                                <td>{parseFloat(data[i].amount).toFixed(4)}</td>
                                <td>{marketData.pools
                                  ? (parseFloat(marketData.pools.busdlptusdPC * data[i].amount).toFixed(4))
                                  : "0.00"
                                }</td>
                                <td>  <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: "#1f9ba0" }}
                                  href={"https://bscscan.com/tx/" + data[i].txn}
                                >
                                  {String(data[i].txn).slice(0, 8)}
                                </a></td>
                              </tr>


                            ) : (
                              // <li>
                              //   <span className="tablered ">{data[i].pool}</span>
                              //   <label className="tablered tcenter">
                              //     {parseFloat(data[i].amount).toFixed(4)}
                              //   </label>
                              //   <span className=" tablered thirdcolright">
                              //     <a
                              //       target="_blank"
                              //       rel="noopener noreferrer"
                              //       href={"https://bscscan.com/tx/" + data[i].txn}
                              //     >
                              //       {String(data[i].txn).slice(0, 8)}
                              //     </a>
                              //   </span>
                              // </li>

                              <tr class="lightredbg">
                                <td>{parseFloat(data[i].amount).toFixed(4)}</td>
                                <td>{
                                  marketData.pools
                                    ? (parseFloat(marketData.pools.busdlptusdPC * data[i].amount).toFixed(4))
                                    : "0.00"
                                }</td>
                                <td>  <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: "#1f9ba0" }}
                                  href={"https://bscscan.com/tx/" + data[i].txn}
                                >
                                  {String(data[i].txn).slice(0, 8)}
                                </a></td>
                              </tr>
                            );
                          })
                        }

                      </tbody>
                    </table>
                  </div>
                </div>
                {/* <div class="tab-pane fade" id="pills-yourstakes" role="tabpanel" aria-labelledby="pills-yourstakes-tab" tabindex="0">
                  <div class="latesttradetable table-responsive">
                    <table class="table table-bordered text-center">
                      <thead>
                        <tr class="darktrbg text-white">
                          <th>Pool</th>
                          <th>Amount</th>
                          <th>Tx.Hash</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="lightgreenbg">
                          <td>12.0321</td>
                          <td>13.36</td>
                          <td>16.34</td>
                        </tr>
                        <tr class="lightredbg">
                          <td>18.4562</td>
                          <td>15.21</td>
                          <td>13.65</td>
                        </tr>
                        <tr class="lightredbg">
                          <td>18.4562</td>
                          <td>15.21</td>
                          <td>13.65</td>
                        </tr>
                        <tr class="lightgreenbg">
                          <td>12.0321</td>
                          <td>13.36</td>
                          <td>16.34</td>
                        </tr>
                        <tr class="lightgreenbg">
                          <td>12.0321</td>
                          <td>13.36</td>
                          <td>16.34</td>
                        </tr>
                        <tr class="lightredbg">
                          <td>18.4562</td>
                          <td>15.21</td>
                          <td>13.65</td>
                        </tr>
                        <tr class="lightgreenbg">
                          <td>12.0321</td>
                          <td>13.36</td>
                          <td>16.34</td>
                        </tr>
                        <tr class="lightredbg">
                          <td>18.4562</td>
                          <td>15.21</td>
                          <td>13.65</td>
                        </tr>
                        <tr class="lightgreenbg">
                          <td>12.0321</td>
                          <td>13.36</td>
                          <td>16.34</td>
                        </tr>
                        <tr class="lightgreenbg">
                          <td>12.0321</td>
                          <td>13.36</td>
                          <td>16.34</td>
                        </tr>
                        <tr class="lightredbg">
                          <td>18.4562</td>
                          <td>15.21</td>
                          <td>13.65</td>
                        </tr>
                        <tr class="lightredbg">
                          <td>18.4562</td>
                          <td>15.21</td>
                          <td>13.65</td>
                        </tr>
                        <tr class="lightredbg">
                          <td>18.4562</td>
                          <td>15.21</td>
                          <td>13.65</td>
                        </tr>
                        <tr class="lightgreenbg">
                          <td>12.0321</td>
                          <td>13.36</td>
                          <td>16.34</td>
                        </tr>
                        <tr class="lightgreenbg">
                          <td>12.0321</td>
                          <td>13.36</td>
                          <td>16.34</td>
                        </tr>
                        <tr class="lightredbg">
                          <td>18.4562</td>
                          <td>15.21</td>
                          <td>13.65</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div> */}
              </div>
            </>
        }



      </div>
    </div >
  );
}
