import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { Spinner } from "reactstrap";
import dataVal from "../../data/Abis.json";
import LoaderCard from "../LoaderCard/LoaderCard";
import ConfirmCard from "../LoaderCard/ConfirmCard";
import ErrorCard from "../LoaderCard/ErrorCard";
import downarrow from "../../images/downarrow.svg";
import egold from "../../images/currency/egold.svg";
import link from "../../images/link.svg";
import crossicon from "../../images/cross.svg";

const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);

export default function StakeCard(props) {
  const { account, isActive, connector } = useWeb3React();
  const [amount, setAmount] = useState("");
  const [egoldBalance, setEgoldBalance] = useState("...");
  const [activeElementId, setActiveElementId] = useState(null);
  const [egoldstakedAmnt, setegoldstaked] = useState("...");
  const [pending, setpending] = useState("...");
  const [endBlk, setendBlk] = useState("...");
  const [startBlk, setstartBlk] = useState("...");
  const [totalEgoldStaked, settotalEgoldStaked] = useState("...");
  const [processState, setprocessState] = useState({
    state: "...",
    data: null,
  });
  const [approvedEgold, setapproveEGOLD] = useState(false);
  const [stakeApproveLoader, setStakeApproveLoader] = useState(false);
  const [btnLoader, setbtnLoader] = useState(false);
  const [unbtnLoader, setunbtnLoader] = useState(false);
  const [currentblockNumber, setcurrentblockNumber] = useState("...");

  const showData = (elementId) => {
    setprocessState({ state: "...", data: null });
    const isActive = elementId === activeElementId;
    setActiveElementId(isActive ? null : elementId);
  };

  useEffect(() => {
    if(props.itemdata.id)
    fetchData();
  }, [props.itemdata]);

  async function fetchData() {
    const cbn = await web3.eth.getBlockNumber();
    setcurrentblockNumber(cbn);
    const farmContractInstance = await new web3.eth.Contract(
      dataVal.stakefarmabi,
      props.itemdata.id
    );
    const egoldcontractInstance = await new web3.eth.Contract(
      dataVal.tokenabi,
      process.env.REACT_APP_EGOLD_ADDR
    );
    try {
      await farmContractInstance.methods
        .pending(0, localStorage.getItem("acct"))
        .call(async function (err, res) {
          if (err) {
            console.log("error-", err);
          }
          if (res == undefined) {
            await farmContractInstance.methods
              .userInfo(0, localStorage.getItem("acct"))
              .call(async function (err, ress) {
                if (err) {
                  console.log("error-", err);
                }
                setpending(noround(web3.utils.fromWei(ress.rewardDebt), 5))
              });
          } else setpending(noround(web3.utils.fromWei(res), 5));
        });
    } catch (e) {
      console.log("error", e);
      setpending(0);
    }

    await farmContractInstance.methods
      .startBlock()
      .call(async function (err, res) {
        console.log("strtblk", res);
        setstartBlk(res);
      });

    await farmContractInstance.methods
      .endBlock()
      .call(async function (err, res) {
        console.log("endblk", res);
        setendBlk(res);
      });

    try {
      await farmContractInstance.methods
        .deposited(0, localStorage.getItem("acct"))
        .call(async function (err, res) {
          if (err) {
            console.log("error-", err);
          }
          console.log("STAKED RES", res, res == undefined);
          if (res == undefined) setegoldstaked(0);
          else setegoldstaked(noround(web3.utils.fromWei(res), 5));
        });
    } catch (e) {
      console.log("error", e);
      setpending(0);
    }

    await egoldcontractInstance.methods
      .balanceOf(localStorage.getItem("acct"))
      .call({}, function (e, res) {
        console.log("BALANCE", noround(web3.utils.fromWei(res), 5));
        setEgoldBalance(noround(web3.utils.fromWei(res), 5));
      });

    await egoldcontractInstance.methods
      .balanceOf(props.itemdata.id)
      .call({}, function (e, res) {
        if (props.cmsdata.attributes.projectName == "EGOLD") {
          settotalEgoldStaked(
            noround(
              web3.utils.fromWei(res) - props.cmsdata.attributes.TokensOffered,
              5
            )
          );
        } else {
          settotalEgoldStaked(noround(web3.utils.fromWei(res), 5));
        }
      });
  }

  function checkerror(err) {
    if (
      err.message ==
        "Please pass numbers as strings or BN objects to avoid precision errors." ||
      err.message.includes(
        "while converting number to string, invalid number value "
      )
    ) {
      setprocessState({
        state: "error",
        data: "请提供有效的输入",
      });
    } else if (JSON.stringify(err.message).includes("transaction underpriced"))
      setprocessState({
        state: "error",
        data: "交易价格被低估。请尝试提高gas价格",
      });
    else
      setprocessState({
        state: "error",
        data: JSON.stringify(err.message),
      });
  }

  async function approveEGOLDToken() {
    setapproveEGOLD(false);
    setStakeApproveLoader(true);
    setprocessState({ state: "...", data: null });
    const webb3 = new Web3(connector.provider);
    const egoldtokencontractInstance = await new webb3.eth.Contract(
      dataVal.tokenabi,
      process.env.REACT_APP_EGOLD_ADDR
    );
    try {
      const estimatedGas = await egoldtokencontractInstance.methods
        .approve(props.itemdata.id, webb3.utils.toWei(amount.toString()))
        .estimateGas({ from: account });

      await egoldtokencontractInstance.methods
        .approve(props.itemdata.id, webb3.utils.toWei(amount.toString()))
        .send({
          from: account,
          gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
          gasPrice: await web3.eth.getGasPrice(),
        })
        .on("receipt", async function (res) {
          setStakeApproveLoader(false);
          setapproveEGOLD(true);
        })
        .on("error", function (error) {
          checkerror(error);
          setStakeApproveLoader(false);
          setapproveEGOLD(false);
        });
    } catch (e) {
      checkerror(e);
      console.log("error-", e);
    }
  }

  const confirmStake = async () => {
    setbtnLoader(true);
    setprocessState({ state: "...", data: null });
    if (localStorage.getItem("acct")) {
      const web3 = new Web3(connector.provider);
      const stakeInstance = new web3.eth.Contract(
        dataVal.stakefarmabi,
        props.itemdata.id
      );
      try {
        const estimatedGas = await stakeInstance.methods
          .deposit(0, web3.utils.toWei(amount.toString()))
          .estimateGas({ from: account });

        await stakeInstance.methods
          .deposit(0, web3.utils.toWei(amount.toString()))
          .send({
            from: account,
            gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
            gasPrice: await web3.eth.getGasPrice(),
          })
          .on("receipt", async function (res) {
            setbtnLoader(false);
            setapproveEGOLD(false);
            setAmount("");
            setprocessState({ state: "done", data: res.transactionHash });
            fetchData();
          })
          .on("error", function (error) {
            setbtnLoader(false);
            checkerror(error);
          });
      } catch (e) {
        console.log("error", e);
        checkerror(e);
      }
    }
  };

  const confirmUnStake = async () => {
    setunbtnLoader(true);
    setprocessState({ state: "...", data: null });
    if (localStorage.getItem("acct")) {
      const web3 = new Web3(connector.provider);
      const stakeInstance = new web3.eth.Contract(
        dataVal.stakefarmabi,
        props.itemdata.id
      );
      try {
        const estimatedGas = await stakeInstance.methods
          .withdraw(0, web3.utils.toWei(egoldstakedAmnt.toString()))
          .estimateGas({ from: account });

        console.log(egoldstakedAmnt);
        await stakeInstance.methods
          .withdraw(0, web3.utils.toWei(egoldstakedAmnt.toString()))
          .send({
            from: account,
            gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
            gasPrice: await web3.eth.getGasPrice(),
          })
          .on("receipt", async function (res) {
            setunbtnLoader(false);
            setprocessState({ state: "done", data: res.transactionHash });
            fetchData();
          })
          .on("error", function (error, receipt) {
            setunbtnLoader(false);
            checkerror(error);
          });
      } catch (e) {
        console.log("error", e);
        checkerror(e);
      }
    }
  };

  function calculateFMperiod(start, end) {
    var difference = end - start;
    difference = difference * 3;
    let days = difference / 86400;
    console.log("DAYS", days);
    return parseInt(days);
  }

  async function getTimeUntilBlock(blockNumber) {
    try {
      const latestBlock = await web3.eth.getBlockNumber();

      if (blockNumber <= latestBlock) {
        return "0d:0h:0m";
      }

      const blockTime = 3;

      const remainingBlocks = blockNumber - latestBlock;
      const remainingTimeInSeconds = remainingBlocks * blockTime;

      const days = Math.floor(remainingTimeInSeconds / (3600 * 24));
      const hours = Math.floor((remainingTimeInSeconds % (3600 * 24)) / 3600);
      const minutes = Math.floor((remainingTimeInSeconds % 3600) / 60);

      const formattedTime = `${days}d:${hours}h:${minutes}m`;

      return `${formattedTime}`;
    } catch (error) {
      return `Error: ${error.message}`;
    }
  }

  function BlockTimer({ targetBlockNumber }) {
    const [remainingTime, setRemainingTime] = useState("...");

    useEffect(() => {
      const init = async () => {
        const timeRemaining = await getTimeUntilBlock(targetBlockNumber);
        setRemainingTime(timeRemaining);
      };
      init();
      const interval = setInterval(async () => {
        const timeRemaining = await getTimeUntilBlock(targetBlockNumber);
        setRemainingTime(timeRemaining);

        if (timeRemaining === "0h:0m:0s") {
          clearInterval(interval);
        }
      }, 30000);

      return () => clearInterval(interval);
    }, [targetBlockNumber]);

    return <p className="tilespdel">{remainingTime}</p>;
  }

  //to not round values while showing balance
  function noround(number, decimalDigits) {
    const powerOfTen = Math.pow(10, decimalDigits);
    const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
    if (Number.isInteger(formattedNumber)) {
      return roundzeroes(formattedNumber, 2);
    } else return formattedNumber;
  }
  //to round decimal points with zeroes
  function roundzeroes(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      var x = noround(num, 2);
      return x; // if value < 1000, nothing to do
    }
  }

  function checkAPR(rws, price, egstkd, tokoff, egprice) {
    var x = noround(
      ((rws * 20 * 60 * 24 * 365 * price) /
        (noround(egstkd - tokoff, 5) * egprice)) *
        100,
      2
    );
    return isNaN(x);
  }

  function findAPR(rws, price, egstkd, tokoff, egprice) {
    var x = noround(
      ((rws * 20 * 60 * 24 * 365 * price) /
        (noround(egstkd - tokoff, 5) * egprice)) *
        100,
      2
    );
    return x + "%";
  }

  function getAPR() {
    return props.cmsdata.attributes.projectName == "EGOLD"
      ? props.dailystate.status == false ||
        props.priceinfo.status == false ||
        props.priceinfo.data[props.cmsdata.attributes.farmAddress] == undefined
        ? "..."
        : checkAPR(
            props.itemdata.rwsblk,
            props.priceinfo.data[props.cmsdata.attributes.farmAddress],
            parseFloat(props.itemdata.egoldstkd),
            parseFloat(props.cmsdata.attributes.TokensOffered),
            parseFloat(props.dailystate.data[0].TAG_USD).toFixed(4)
          )
        ? "0%"
        : findAPR(
            props.itemdata.rwsblk,
            props.priceinfo.data[props.cmsdata.attributes.farmAddress],
            parseFloat(props.itemdata.egoldstkd),
            parseFloat(props.cmsdata.attributes.TokensOffered),
            parseFloat(props.dailystate.data[0].TAG_USD).toFixed(4)
          )
      : props.dailystate.status == false ||
        props.priceinfo.status == false ||
        props.priceinfo.data[props.cmsdata.attributes.farmAddress] == undefined
      ? "..."
      : checkAPR(
          props.itemdata.rwsblk,
          props.priceinfo.data[props.cmsdata.attributes.farmAddress],
          parseFloat(props.itemdata.egoldstkd),
          0,
          parseFloat(props.dailystate.data[0].TAG_USD).toFixed(4)
        )
      ? "0%"
      : findAPR(
          props.itemdata.rwsblk,
          props.priceinfo.data[props.cmsdata.attributes.farmAddress],
          parseFloat(props.itemdata.egoldstkd),
          0,
          parseFloat(props.dailystate.data[0].TAG_USD).toFixed(4)
        );
  }
  return (
    <div
      class="stakeliverow"
      style={
        props.selected != "all" && (egoldstakedAmnt == 0 || pending == 0)
          ? { display: "none" }
          : {}
      }
    >
      <div class="slfsgroup">
        <div class="slfirst">
          {props.type == "live" ? (
            <span class="stakelivebtn">APR : {getAPR()}</span>
          ) : (
            <span class="stakelivebtn"> APR: ...% </span>
          )}
          {props.type == "live" && <span class="livebadge"> 居住 </span>}
          {props.type == "completed" && (
            <span class="completedbadge"> 完成 </span>
          )}
          {props.type == "upcoming" && (
            <span class="upcomingbadge"> 即将到来的
            </span>
          )}
        </div>
        <div class="slsecond">
          <span>
            {" "}
            权益质押: EGOLD <img src={egold} alt="" style={{ height: "20px" }} />
          </span>
          <span class="float-right">
            {" "}
            赚取:{" "}
            {props.cmsdata.attributes.projectName == "EGOLD"
              ? props.cmsdata.attributes.projectName
              : props.itemdata.tokname}{" "}
            <img
              src={props.cmsdata.attributes.icon.data.attributes.url}
              style={{ height: "20px" }}
              alt=""
            />
          </span>
        </div>
      </div>
      {egoldstakedAmnt > 0 || pending > 0 ? (
        <div class="slthird">
          <span> 你押注了: {egoldstakedAmnt} EGOLD </span>
          <span class="float-right">
            {" "}
            你赚取了: {pending}{" "}
            {props.cmsdata.attributes.projectName == "EGOLD"
              ? props.cmsdata.attributes.projectName
              : props.itemdata.tokname}{" "}
          </span>
        </div>
      ) : (
        ""
      )}
      <div
        className={`slfourth ${activeElementId ? "active" : ""}`}
        id="fourslidebtn"
      >
        <div class="slfourthbtns">
          <button
            class="slfourthbtn"
            id="detbtn"
            onClick={() => showData("fourthslidecontent", "detbtn")}
          >
            {" "}
            详情 <img src={downarrow} alt="" />
          </button>
          <button
            class="slfourthbtn"
            id="infobtn"
            onClick={() => showData("fourthslidecontent2", "infobtn")}
          >
            {" "}
            {props.cmsdata.attributes.projectName == "EGOLD"
              ? props.cmsdata.attributes.projectName
              : props.itemdata.tokname}{" "}
            信息 <img src={downarrow} alt="" />
          </button>
        </div>
        <div class="slfourthbtns">
          {pending > 0 && (
            <div
              className={`slcollectbtn ${
                activeElementId === "fourthslidecontent3" ? "active" : ""
              }`}
              id="cltbtn"
              onClick={() => showData("fourthslidecontent3", "cltbtn")}
              style={{ cursor: "pointer" }}
            >
              {" "}
              收集{" "}
            </div>
          )}
          {props.type == "live" && (
            <div
              className={`slstakebtn ${
                activeElementId === "fourthslidecontent4" ? "active" : ""
              }`}
              id="stkbtn"
              onClick={() => showData("fourthslidecontent4", "stkbtn")}
              style={{ cursor: "pointer" }}
            >
              {" "}
              质押{" "}
            </div>
          )}
        </div>
      </div>
      <div
        id="fourthslidecontent"
        className={`slide-down-content ${
          activeElementId === "fourthslidecontent" ? "active" : ""
        }`}
      >
        <div class="performancehistroy pb-0 slfourthdel">
          <div>
            <p class="minerownep">
              {props.cmsdata.attributes.projectName == "EGOLD"
                ? props.cmsdata.attributes.projectName
                : props.itemdata.tokname}{" "}
              可用的
            </p>
            <p class="minerownep2">{props.cmsdata.attributes.TokensOffered}</p>
          </div>
          <div>
            <p class="minerownep">EGOLD 权益质押 </p>
            <p class="minerownep2">{totalEgoldStaked}</p>
          </div>
          <div>
            {props.type == "live" ? (
              <>
                <p class="minerownep">APR </p>
                <p class="minerownep2">{getAPR()}</p>
              </>
            ) : (
              <>
                <p class="minerownep">APR </p>
                <p class="minerownep2">...%</p>
              </>
            )}
          </div>
        </div>
        <div class="performancehistroy slfourthdel">
          <div>
            <p class="minerownep">农耕时期</p>
            <p class="minerownep2">
              ~
              {startBlk == "..." || endBlk == "..."
                ? "..."
                : calculateFMperiod(startBlk, endBlk)}{" "}
              天
            </p>
          </div>
          <div>
            <p class="minerownep">启动块</p>
            <a href={"https://bscscan.com/block/" + startBlk} target="_blank">
              <p class="minerownep2">#{startBlk}</p>
            </a>
          </div>
          <div>
            <p class="minerownep">端块 </p>
            <a href={"https://bscscan.com/block/" + endBlk} target="_blank">
              <p class="minerownep2 ">#{endBlk}</p>
            </a>
          </div>
        </div>
      </div>
      <div
        class="slide-down-content"
        id="fourthslidecontent2"
        className={`slide-down-content ${
          activeElementId === "fourthslidecontent2" ? "active" : ""
        }`}
      >
        <div class="slidecontent">
          <p class="slidecontentp">{props.cmsdata.attributes.description}</p>
          <div class="tokenmorebtsn">
            <a
              href={
                "https://bscscan.com/address/" +
                props.cmsdata.attributes.tokensAddress
              }
              target="_blank"
              style={{ color: "unset" }}
            >
              <span>
                <img src={link} alt="" />
                代币合约t{" "}
              </span>
            </a>
            <a
              style={{ color: "unset" }}
              href={
                props.cmsdata.attributes.website.includes("http")
                  ? props.cmsdata.attributes.website
                  : "//" + props.cmsdata.attributes.website
              }
              target="_blank"
            >
              <span>
                <img src={link} alt="" />
                更多信息{" "}
              </span>
            </a>
          </div>
        </div>
      </div>
      <div
        class="slide-down-content"
        id="fourthslidecontent4"
        className={`slide-down-content ${
          activeElementId === "fourthslidecontent4" ? "active" : ""
        }`}
      >
        <div class="slidecontent">
          <p class="stakegoldp">
          质押 EGOLD{" "}
            <img
              src={crossicon}
              alt=""
              class="float-right"
              onClick={() => {
                setprocessState({ state: "...", data: null });
                setActiveElementId(null);
              }}
              style={{ cursor: "pointer" }}
            />
          </p>
          <div class="sliderinputsec">
            <div class="sliderinput">
              <input
                type="text"
                class="form-control "
                value={amount}
                placeholder="Enter Amount"
                onChange={(amount) => {
                  setAmount(amount.target.value);
                }}
              />
              <p class="labelegold">Egold</p>
              <p
                class="labelmax"
                style={{ cursor: "pointer" }}
                onClick={(e) => setAmount(egoldBalance)}
              >
                最大限度
              </p>
            </div>
            <p class="labelavailbalp">平衡: {egoldBalance} EGOLD</p>
          </div>
          <div class="selectcurbtnsec">
            <button
              class="btn-outline-color-secondary"
              disabled={approvedEgold}
              onClick={() => {
                if (parseFloat(amount) > 0) {
                  if (parseFloat(amount) > parseFloat(egoldBalance)) {
                    setprocessState({
                      state: "error",
                      data: "余额不足",
                    });
                  } else {
                    approveEGOLDToken();
                  }
                } else {
                  setprocessState({
                    state: "error",
                    data: "请输入金额",
                  });
                }
              }}
            >
              {stakeApproveLoader ? <Spinner size="sm" /> : "通过"}
            </button>
            <button
              class="btn-color-primary"
              style={{ border: "0px" }}
              disabled={!approvedEgold}
              onClick={() => {
                if (parseFloat(amount) > 0) {
                  if (parseFloat(amount) > parseFloat(egoldBalance)) {
                    setprocessState({
                      state: "error",
                      data: "余额不足",
                    });
                  } else {
                    confirmStake();
                  }
                } else {
                  setprocessState({
                    state: "error",
                    data: "请输入金额",
                  });
                }
              }}
            >
              {btnLoader ? <Spinner size="sm" /> : "Stake"}
            </button>
          </div>
          {processState.state == "..." ? (
            ""
          ) : processState.state == "processing" ? (
            <LoaderCard bg="dark" />
          ) : processState.state == "done" ? (
            <ConfirmCard tx={processState.data} bg="dark" />
          ) : (
            <ErrorCard err={processState.data} bg="dark" />
          )}
        </div>
      </div>
      <div
        class="slide-down-content"
        id="fourthslidecontent3"
        className={`slide-down-content ${
          activeElementId === "fourthslidecontent3" ? "active" : ""
        }`}
      >
        <div class="slidecontent">
          <p class="stakegoldp">
          收集{" "}
            {props.cmsdata.attributes.projectName == "EGOLD"
              ? props.cmsdata.attributes.projectName
              : props.itemdata.tokname}{" "}
            <img
              src={crossicon}
              alt=""
              class="float-right"
              onClick={() => {
                setprocessState({ state: "...", data: null });
                setActiveElementId(null);
              }}
              style={{ cursor: "pointer" }}
            />
          </p>
          {parseInt(endBlk) < parseInt(currentblockNumber) ? (
            <p className="tilespdel" style={{paddingLeft:"5px"}}>0h:0m:0s</p>
          ) : (
            <div className="unstaketimer">
              <p>您可以取消质押 : </p>
              <BlockTimer targetBlockNumber={endBlk} />
            </div>
          )}
          <div class="selectcurbtnsec" style={{ margin: "10px 0px" }}>
            <button
              style={{ width: "100%", border: "0px" }}
              class="btn-color-primary"
              disabled={parseInt(endBlk) > parseInt(currentblockNumber)}
              onClick={() => {
                confirmUnStake();
              }}
            >
              {unbtnLoader ? <Spinner size="sm" /> : "收集和取消质押"}
            </button>
          </div>
          {processState.state == "..." ? (
            ""
          ) : processState.state == "processing" ? (
            <LoaderCard bg="dark" />
          ) : processState.state == "done" ? (
            <ConfirmCard tx={processState.data} bg="dark" />
          ) : (
            <ErrorCard err={processState.data} bg="dark" />
          )}
        </div>
      </div>{" "}
    </div>
  );
}
