import React, { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { Spinner } from 'reactstrap';
import dataVal from "../../../data/Abis.json";
import ErrorCard from "../../LoaderCard/ErrorCard";
import LoaderCard from "../../LoaderCard/LoaderCard";
import ConfirmCard from "../../LoaderCard/ConfirmCard";
export default function LPAddWidget(props) {
  const Web3 = require("web3");
  const { account, isActive, connector } = useWeb3React();

  const PANCAKE_FACTORY_ABI = dataVal.pancakeFactoryAbi
  const BUSD_ADDRESS = dataVal.busdtokencontract;
  const EGOLD_ADDRESS = dataVal.egoldtokencontract;
  const TOKEN_ABI = dataVal.busdtokenabi;
  const BUSDFARM_ABI = dataVal.lpfarmabi;
  const PANCAKE_FACTORY_ADDRESS = process.env.REACT_APP_PANCAKE_FACTORY_ADDR;
  const BUSD_EGOLD_FARM_ADDRESS = process.env.REACT_APP_BUSD_EGOLD_FARM;


  const [liquidityContract, setliquidityContract] = useState("..");
  const [liquidityTokenBlnce, setliquidityTokenBlnce] = useState("0.0000");
  const [addLQamnt, setaddLQamnt] = useState(null);
  const [disableBTN, setdisableBTN] = useState(true);
  const [processState, setprocessState] = useState({
    state: "...",
    data: null,
  });
  const [submitLoader, setsubmitLoader] = useState(false);
  const [approveLoader, setapproveLoader] = useState(false);
  const [tokenapp, setTokenapp] = useState(false); //token approve button enabled

  useEffect(() => {
    console.log("actiiivee", account);
    if (isActive) {
      console.log("active");
      fetchLQBalance();
    } else {
      console.log("inactive");
      setliquidityTokenBlnce("0.0000");
      setaddLQamnt("");

    }
  }, [account]);

  //to not round values while showing balance
  function noround(val, x) {
    if (!val) return 0;
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }

  const fetchLQBalance = async () => {
    if (localStorage.getItem("acct") && account) {
      const web3 = new Web3(process.env.REACT_APP_RPC);
      const pancakeFactorycontractInstance = await new web3.eth.Contract(
        PANCAKE_FACTORY_ABI,
        PANCAKE_FACTORY_ADDRESS
      );
      console.log("json interface", pancakeFactorycontractInstance)
      await pancakeFactorycontractInstance.methods
        .getPair(BUSD_ADDRESS, EGOLD_ADDRESS)
        .call({}, function (e, res) {
          setliquidityContract(res);
          const LQInstance = new web3.eth.Contract(TOKEN_ABI, res);
          LQInstance.methods
            .balanceOf(account)
            .call({}, function (e, resbalance) {
              setliquidityTokenBlnce(
                noround(web3.utils.fromWei(resbalance), 4)
              );
            });
        });
    }
  };

  const getOnchangeval = (e) => {
    setaddLQamnt(e);
    // var weiToken = web3.utils.toWei(e, "ether");
  };
  function getMaxF() {
    getOnchangeval(liquidityTokenBlnce);
  }

  function getReceipt(hash) {
    const web3 = new Web3(process.env.REACT_APP_RPC);
    let test = [];

    return new Promise(function (resolve) {
      const interval = setInterval(() => {
        web3.eth.getTransactionReceipt(hash, function (e, data) {
          if (data == null) {
            console.log(
              "Could not find a transaction for your id! ID you provided was " +
              hash
            );
          } else {
            console.log("receipt", data);
            test = data;
            clearInterval(interval);
            resolve(data);
          }
        });
      }, 2000);
    });
  }

  const redirect = (tx) => {
    console.log("txxx", tx);
    window.open("https://bscscan.com/tx/" + tx, "_blank");
  };

  //approval
  const approveLPT = async () => {
    setdisableBTN(false)
    setapproveLoader(true)
    setprocessState({
      state: "...",
      data: null
    })

    try {
      if (localStorage.getItem("acct")) {
        const webb3 = new Web3(connector.provider);
        const approveLPTInstance = await new webb3.eth.Contract(
          TOKEN_ABI,
          liquidityContract
        );
        console.log("LPT AMNT", addLQamnt);
        var amnt = String(addLQamnt);

        const estimatedGas = await approveLPTInstance.methods
        .approve(BUSD_EGOLD_FARM_ADDRESS, webb3.utils.toWei(amnt.toString()))
          .estimateGas({ from: account });

        await approveLPTInstance.methods
          .approve(BUSD_EGOLD_FARM_ADDRESS, webb3.utils.toWei(amnt.toString()))
          .send({
            from: account,
            gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
            gasPrice: await webb3.eth.getGasPrice(),
          })
          .on("receipt", async function (res) {
            setdisableBTN(true)
            setTokenapp(true)
            setapproveLoader(false)
          })
          .on("error", function (error, receipt) {
            setapproveLoader(false)

            checkerror(error);
            console.log("error", error);
          });
      } else {
        setapproveLoader(false)
        setprocessState({
          state: "error",
          data: "钱包未连接"
        });
      }
    } catch (error) {
      setapproveLoader(false)
      console.log("error-", error);
      checkerror(error);

    }
  };
  function checkerror(err) {
    if (
      err.message ==
      "Please pass numbers as strings or BN objects to avoid precision errors." || err.message.includes("while converting number to string, invalid number value ")
    ) {
      setprocessState({
        state: "error",
        data: "请提供有效的输入",
      });
    } else if (JSON.stringify(err.message).includes("transaction underpriced"))
      setprocessState({
        state: "error",
        data: "交易价格被低估。请尝试提高gas价格",
      });
    else
      setprocessState({
        state: "error",
        data: JSON.stringify(err.message),
      });
  }

  const depositLPT = async () => {
    setdisableBTN(false)
    setsubmitLoader(true)
    setprocessState({
      state: "...",
      data: null
    })

    try {
      if (localStorage.getItem("acct")) {
        setprocessState({
          state: "processing",
          data: null
        })
        const webb3 = new Web3(connector.provider);
        const depositLPTInstance = await new webb3.eth.Contract(
          BUSDFARM_ABI,
          BUSD_EGOLD_FARM_ADDRESS
        );

        const estimatedGas = await depositLPTInstance.methods
        .deposit(0, webb3.utils.toWei(addLQamnt.toString()))
          .estimateGas({ from: account });

        await depositLPTInstance.methods
          .deposit(0, webb3.utils.toWei(addLQamnt.toString()))
          .send({
            from: account,
            gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
            gasPrice: await webb3.eth.getGasPrice(),
          })
          .on("receipt", async function (res) {
            setaddLQamnt("")
            fetchLQBalance();
            setsubmitLoader(false)
            setTokenapp(false)
            setprocessState({
              state: "done",
              data: res.transactionHash

            });
          })
          .on("error", function (error, receipt) {
            setsubmitLoader(false)
            checkerror(error)
          });
      } else {
        setsubmitLoader(false)
        setTokenapp(false)
        setprocessState({
          state: "error",
          data: "钱包未连接"
        });
      }
    } catch (error) {
      console.log("error-", error);
      // setTokenapp(false)
      setsubmitLoader(false)
      checkerror(error)
    }
  };

  return (
    <div class="detailCard secpadding mb20">

      <div class="fs12 mb10" style={{ display: "flex", justifyContent: "space-between" }}>
        <p >LPT Amount

        </p>
        <p>
        平衡 : {liquidityTokenBlnce}
        </p>
      </div>


      <div class="sliderinput mb15">
        <input type="text" class="form-control " value={addLQamnt}
          onChange={(e) => getOnchangeval(e.target.value)} />
        <p class="labelusemax" style={{ cursor: "pointer" }} onClick={() => getMaxF()}>最大使用</p>
      </div>
      <div class="selectcurbtnsec">
        <button class="btn-outline-color-secondary" disabled={tokenapp} style={{ cursor: "pointer" }} onClick={() => approveLPT()}> {
          approveLoader ? <Spinner color='black' style={{ height: 16, width: 16 }} /> : "批准"
        }</button>
        <button class="btn-color-primary" disabled={!tokenapp} style={!tokenapp ? { cursor: "not-allowed", border: "unset" } : { border: "unset", cursor: "pointer" }} onClick={() => !tokenapp ? null : depositLPT()}> {
          submitLoader ? <Spinner color='black' style={{ height: 16, width: 16 }} /> : "订金"
        }</button>
      </div >
      {/* {processState.state == "..." ? (
        ""
      ) :
      processState.state == "..." ? (
        ""
      ) :
  
      processState.state == "done" ? (
        <div class="transictionconfirmsec" style={{ padding: 20, borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}>
          <p class="text1 mb20">Transaction Confirmed </p>

          <a
            href={"https://bscscan.com/tx/" + processState.data}
            target="_blank"
            class="btn-outline-grey mb20"
          >
            View Transaction
          </a>
        </div>
      ) : (

        <ErrorCard err={processState.data} />



      )
      } */}
      {processState.state == "..." ? (
        ""
      ) : processState.state == "processing" ? (
        <LoaderCard />
      ) : processState.state == "done" ? (
        <ConfirmCard tx={processState.data} />
      ) : (
        <ErrorCard err={processState.data} />
      )}



    </div >
  );
}
