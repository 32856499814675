import React, { useEffect, useState,useLayoutEffect } from "react";
import PillsMenu from "./Menu/PillsMenu";
import StakePillsMenu from "./Stake/StakePillsMenu";
import StakeCard from "./Stake/StakeCard";
import WalletButton from "./Buttons/WalletButton/WalletButton";
import { useWeb3React } from "@web3-react/core";
import dataVal from "../data/Abis.json";
import { Spinner } from "reactstrap";

const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);
export default function Swap() {
  const { account, isActive, connector } = useWeb3React();
  const [Listitems, setListitems] = useState({ data: [], status: false });
  const [CMSdata, setCMSData] = useState({ data: [], status: false });
  const [priceInfo, setPriceInfo] = useState({ data: null, status: false });

  let x = {};
  let counter = "...";

  useLayoutEffect(() => {
    getSummary();
    fetchData();
  }, []);

  useEffect(() => {
    getSummary();
  }, [account]);

  const getSummary = async () => {
    const url =
      "https://seashell-app-tpow8.ondigitalocean.app/api/projects?populate=icon";
    fetch(url, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer " +
          "f708510f4b92b800e8e7a68cb9926e492f713bc9159992037cc521d4a5ff4b3b0f7e70bd0819cbdcf329970229bdb9d67a0500568864f89f4aae596d2b1d9536c693c8b7defb4ee452225796aed78277e5176fda17d4f60cb2ac814214040a684554f8466ad8c7857a762e8ada3c84a8a030139b63f369f304898ae6605e5ebf",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(
        (data) => setCMSData({ data: data, status: true }) + getpriceInfo(data)
      )
      .catch((error) => console.error(error));
  };

  function getpriceInfo(data) {
    let priceInfo = {};
    data.data.map(function (items) {
      const urlprice =
        "https://egold-marketdata.herokuapp.com/v1/summary/getTokenPrices/" +
        items.attributes.tokensAddress;
      fetch(urlprice)
        .then(function (response) {
          return response.json();
        })
        .then(function (prices) {
          var info = {};
          if ("price" in prices) {
            let keyp = items.attributes.farmAddress;
            let pricep = prices.price;
            priceInfo = Object.assign(priceInfo, { [keyp]: pricep });

            setPriceInfo({ data: priceInfo, status: true });
          }

          console.log("PRICE INFO", priceInfo);
        });
    });

  }

  function setDataformat(
    name,
    index,
    apiData,
    endBres,
    resTKO,
    resEgold,
    startBres,
    rwsblk
  ) {
    let data = {
      id: apiData._id,
      session_end_date: apiData.session_end_date,
      tokname: name,
      tokens: parseFloat(web3.utils.fromWei(resTKO)).toFixed(2),
      participants: apiData.participants,
      egoldstkd: parseFloat(web3.utils.fromWei(resEgold)).toFixed(8),
      apy: "25.76",
      endblk: endBres,
      startblk: startBres,
      rwsblk: web3.utils.fromWei(rwsblk),
    };
    return data;
  }

  async function fetchData() {
    const url = "https://egold-staking-transactions.herokuapp.com/getallfarms";
    let previousList = [];
    const egoldcontractInstance = await new web3.eth.Contract(
      dataVal.tokenabi,
      process.env.REACT_APP_EGOLD_ADDR
    );
    var count = null;
    fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(function (farmdata) {
        farmdata.data.length > 0
          ? (count = farmdata.data.some((item) => item.active === false)) + count
            ? farmdata.data.map(async (items, index) => {
              if (items.active == false) {
                const farmContractInstance = await new web3.eth.Contract(
                  dataVal.stakefarmabi,
                  items._id
                );
                await farmContractInstance.methods
                  .erc20()
                  .call(async function (err, res1) {
                    const farmContractNameInstance =
                      await new web3.eth.Contract(dataVal.stakenameabi, res1);
                    await farmContractNameInstance.methods
                      .name()
                      .call(async function (err, res) {
                        await farmContractInstance.methods
                          .endBlock()
                          .call(async function (err, endBres) {
                            await farmContractInstance.methods
                              .startBlock()
                              .call(async function (err, startBres) {
                                await farmContractInstance.methods
                                  .rewardPerBlock()
                                  .call(async function (err, rwsblk) {
                                    await farmContractNameInstance.methods
                                      .balanceOf(items._id)
                                      .call(async function (err, resTKO) {
                                        await egoldcontractInstance.methods
                                          .balanceOf(items._id)
                                          .call({}, function (e, resEgold) {
                                            let formattedData = setDataformat(
                                              res,
                                              index,
                                              items,
                                              endBres,
                                              resTKO,
                                              resEgold,
                                              startBres,
                                              rwsblk
                                            );
                                            previousList.push(formattedData);
                                            setListitems({
                                              data: previousList,
                                              status: true,
                                            });
                                          });
                                      });
                                  });
                              });
                          });
                      });
                  });
              }
            })
            : setListitems({ data: [], status: true })
          : setListitems({ data: [], status: true });
      })

      .catch(function (error) {
        console.log("Requestfailed", error);
      });
  }

  //to not round values while showing balance
  function noround(number, decimalDigits) {
    const powerOfTen = Math.pow(10, decimalDigits);
    const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
    if (Number.isInteger(formattedNumber)) {
      return roundzeroes(formattedNumber, 2);
    } else return formattedNumber;
  }
  //to round decimal points with zeroes
  function roundzeroes(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      var x = noround(num, 2);
      return x; // if value < 1000, nothing to do
    }
  }


  return (
    <>
      <div
        class="mainsection"
        style={{
          position: "relative",
        }}
      >
        <div class="minertabsec secpadding lightgrey-bg brrr mb20">
          <PillsMenu activeval="stake" />
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-trade" role="tabpanel" aria-labelledby="pills-trade-tab" tabindex="0">
              <div class="wallettabsec egoldsubtabsec lightgrey-bg brtlr">
                <StakePillsMenu activeval="completed" />
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-swap"
                    role="tabpanel"
                    aria-labelledby="pills-swap-tab"
                    tabindex="0"
                  >
                    {Listitems.status && CMSdata.status ? (
                      Listitems.data.length != 0 ? (
                        ((counter = 0),
                        console.log("LISTITEMS-",Listitems.data),
                          Listitems.data
                            .sort((a, b) => b.endblk - a.endblk)
                            .map((dataitem, index) => {
                              x = CMSdata.data.data.find(
                                (obj) =>
                                  obj.attributes.farmAddress === dataitem.id
                              );
                             
                              if (x != undefined) {                                
                                counter = counter + 1;
                                console.log("xheree",dataitem.id)
                                return <StakeCard itemdata={dataitem} cmsdata={x} type="completed" selected="all" />;
                              }
                            }))
                      ) : (
                        <p className="warninfobox">
                          没有可用的已完成项目
                        </p>
                      )
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Spinner color="black" size={20} />
                      </div>
                    )}
                    {counter == "..." ? (
                      ""
                    ) : counter == 0 ? (
                      <p className="warninfobox">
                      没有可用的已完成项目
                    </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
        <div
          id="overlay"
          style={
            localStorage.getItem("acct") || isActive ? { display: "none" } : {}
          }
        >
          <div className="connectinoverlay">
            {" "}
            <WalletButton />
          </div>
          请连接您的钱包以继续
        </div>
      </div>
    </>
  );
}
