import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import PillsMenu from "./Menu/PillsMenu";
import TradePillsMenu from "./Trade/TradePillsMenu";
import PoolData from "./Trade/PoolData";
import LiquidityAddWidget from "./Trade/Liquidity/v1/LiquidityAddWidget";
import LiquidityRemoveWidget from "./Trade/Liquidity/v1/LiquidityRemoveWidget";
import LiquidityData from "./Trade/Liquidity/v1/LiquidityData";
import WalletButton from "./Buttons/WalletButton/WalletButton";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";

export default function Liquidity() {
  const { account, isActive, connector } = useWeb3React();
  return (
    <>
      <div
        class="mainsection"
        style={{
          position: "relative",
        }}
      >
        <div class="minertabsec secpadding lightgrey-bg brrr mb20">
          <PillsMenu activeval="trade" />
          <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-trade" role="tabpanel" aria-labelledby="pills-trade-tab" tabindex="0">
              <div class="wallettabsec egoldsubtabsec lightgrey-bg brtlr">
              <TradePillsMenu activeval="liquidity"/>
                <div class="tab-content" id="pills-tabContent">
                 
                  <div class="tab-pane fade show active" id="pills-liquidity" role="tabpanel" aria-labelledby="pills-liquidity-tab" tabindex="0">
                  
                  <LiquidityAddWidget/>
                   <LiquidityRemoveWidget/>
                   <LiquidityData/>
                  </div>
                
                </div>
              </div>
            </div>
          </div>
        </div>
       
        <div
          id="overlay"
          style={
            localStorage.getItem("acct") || isActive ? { display: "none" } : {}
          }
        >
          <div className="connectinoverlay">
            {" "}
            <WalletButton />
          </div>
          请连接您的钱包以继续
        </div>
      </div>
    </>
  );
}
