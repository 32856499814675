import React, { useState, useEffect } from "react";
import dataVal from "../../../data/Abis.json";
import { useWeb3React } from "@web3-react/core";
import { Spinner } from "reactstrap";
const Web3 = require("web3");
export default function MinerInfoJunkyard(props) {
  const web3 = new Web3(process.env.REACT_APP_RPC);
  const { account, isActive, connector } = useWeb3React();
  const [minerName, setMinerName] = useState("...");
  const [maxClaimCount, setmaxClaimCount] = useState(10);
  const [loaderBTN, setloaderBTN] = useState(false);
  const [processState, setprocessState] = useState({
    state: "...",
    data: null,
  });

  useEffect(() => {
    getMinerInfo();
  }, []);

  console.log("!!props", props);

  const getMinerInfo = async () => {
    const contractInstance = new web3.eth.Contract(
      dataVal.mregistryabi,
      process.env.REACT_APP_MINER_REGISTRY
    );

    await contractInstance.methods
      .fetchMinerInfo(props.type.mType)
      .call()
      .then((res) => {
        setMinerName(res.name);
      });
  };

  async function deleteminer() {
    setloaderBTN(true);
    let fromaddr = localStorage.getItem("acct");
    let toaddr = "0x0000000000000000000000000000000000000000";

    const w3 = new Web3(connector.provider);
    let NFT = new w3.eth.Contract(
      dataVal.nftAbi,
      process.env.REACT_APP_NFT_ADDR
    );

    try {
      console.log("propsid", props.type._id, fromaddr, toaddr);

      const estimatedGas = await NFT.methods
        .transferFrom(fromaddr, toaddr, props.type._id)
        .estimateGas({ from: account });

      await NFT.methods
        .transferFrom(fromaddr, toaddr, props.type._id)
        .send({
          from: fromaddr,
          gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
          gasPrice: await web3.eth.getGasPrice(),
        })
        .on("receipt", async function (res) {
          setloaderBTN(false);
          setprocessState({
            state: "done",
            data: res,
          });
        })
        .on("error", function (error, receipt) {
          setloaderBTN(false);
          setprocessState({
            state: "error",
            data: "删除矿工时出错",
          });
        })
        .catch((error) => {
          setloaderBTN(false);
          setprocessState({
            state: "error",
            data: "删除矿工时出错",
          });
        });
    } catch (e) {
      setloaderBTN(false);
      setprocessState({
        state: "error",
        data: "删除矿工时出错",
      });
    }
  }

  return (
    <div>
      <div class="mineraccordian">
        <div class="accordion" id="accordionExample">
          <div class="mineaccordianall">
            <div class="mineaccorsec">
              <div class="minerstate">
                <div class="mineaccorimg">
                  {minerName != "..." ? (
                    <img
                      src={require("../../../images/miners/" +
                        minerName +
                        ".png")}
                      alt=""
                      class="img-fluid"
                    />
                  ) : null}
                </div>
                <span class="expirebadge">已到期</span>
              </div>
              <div class="mineaccorbtnsec">
                <div class="mineaccorbtns mb15">
                  <a class="mineaccorbtn active">矿工号: {props.type._id}</a>
                  <a class="mineaccorbtn">功率: {props.type.mhash} Mhs</a>
                </div>
                <div class="mineaccorbtns">
                  <a class="mineaccorbtn">矿工类型: {minerName}</a>
                  <a class="mineaccorbtn">
                  已经认领: {Number(props.consumed.ctr[props.type._id])}/
                    {maxClaimCount}
                  </a>
                </div>
              </div>
            </div>
            <div
              class="accordion-item mineraccordianitem mineaccoritem"
              style={{ background: "#e0e7ea" }}
            >
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button collapsed mineraccordianbtn minebtn"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={"#collapseOne" + props.index}
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  {" "}
                  矿工详情{" "}
                </button>
              </h2>
              <div
                id={"collapseOne" + props.index}
                class="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body mineraccordianbody">
                  <div
                    class="selectcurbtnsec appactiveminer"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => deleteminer(props.type._id)}
                  >
                    <a class="btn-color-primary w-100">
                      {loaderBTN ? (
                        <Spinner
                          color="black"
                          style={{ height: 16, width: 16 }}
                        />
                      ) : (
                        "发送至垃圾场"
                      )}
                    </a>
                  </div>
                  {processState.state == "..." ? (
                    ""
                  ) : processState.state == "done" ? (
                    <div
                      class="transictionconfirmsec"
                      style={{
                        padding: 20,
                        borderBottomLeftRadius: 8,
                        borderBottomRightRadius: 8,
                      }}
                    >
                      <p class="text1 mb20">交易已确认 </p>

                      <a
                        href={"https://bscscan.com/tx/" + processState.data}
                        target="_blank"
                        class="btn-outline-grey mb20"
                      >
                        查看交易
                      </a>
                    </div>
                  ) : (
                    <div class="transictionconfirmsec">
                      <p class="text1 mb20" style={{ color: "red" }}>
                        {processState.data}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
