import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import dataVal from "../../data/Abis.json";

const TotaleSupply = () => {
  const Web3 = require("web3");
  const [data, setData] = useState([1]);

  const [miningStats, setMiningStats] = useState({
    data: "...",
    loading: true,
  });
  const [liquidityBUSDBlnce, setliquidityBUSDBlnce] = useState("...");
  const [liquidityEGOLDBlnce, setliquidityEGOLDBlnce] = useState("...");
  const [liquidityEGOLDv2Blnce, setliquidityEGOLDv2Blnce] = useState("...");
  const [mintedtotal, setMintedTotal] = useState("...");
  const [unclaimedtotal, setUnclaimedTotal] = useState("...");
  const [staketotal, setStakeTotal] = useState("...");
  const [salerburn, setSalerburn] = useState({ data: "...", loading: true });
  const [burneg, setBurneg] = useState("...");
  const [burnegv2, setBurnegv2] = useState("...");
  const [burnbusd, setBurnbusd] = useState("...");
  const [burnwbnb, setBurnwbnb] = useState("...");
  const [farmyield, setFarmYield] = useState(0);
  const [farmrpb, setFarmRPB] = useState(0);
  const [totalblocks, setTB] = useState(0);

  const web3 = new Web3(process.env.REACT_APP_RPC);

  const farmInstance = new web3.eth.Contract(
    dataVal.farmAbi,
    process.env.REACT_APP_FARMING_ADDR
  );
  const LIQUIDITY_ABI = dataVal.liquidityabi;
  const pancakeFactorycontractInstance = new web3.eth.Contract(
    dataVal.pcfactoryabi,
    process.env.REACT_APP_PANCAKE_FACTORY_ADDR
  );
  const busdcontractInstance = new web3.eth.Contract(
    dataVal.tokenabi,
    process.env.REACT_APP_BUSD_ADDR
  );
  const egoldcontractInstance = new web3.eth.Contract(
    dataVal.tokenabi,
    process.env.REACT_APP_EGOLD_ADDR
  );
  const egoldv2contractInstance = new web3.eth.Contract(
    dataVal.tokenabi,
    process.env.REACT_APP_EGOLDV2_ADDR
  );
  const wbnbcontractInstance = new web3.eth.Contract(
    dataVal.tokenabi,
    process.env.REACT_APP_WBNB_ADDR
  );

  useEffect(() => {
    getSummary();
  }, []);

  const getSummary = async () => {
    const urlMiningStats = "https://egold-miner.herokuapp.com/miningStats";
    const salerburn = "https://auth.egoldfarm.com/totalegburned";
    const totalstakeurl =
      "https://egold-staking-transactions.herokuapp.com/totalegoldstaked";

    //to fetch individual treasury pending burn data
    await egoldcontractInstance.methods
      .balanceOf("0xc2Cd8AC581Ff067cA99F1be53cC7941FF199e540")
      .call({}, function (e, res) {
        setBurneg(noround(web3.utils.fromWei(res), 3));
      });
    await egoldv2contractInstance.methods
      .balanceOf("0xc2Cd8AC581Ff067cA99F1be53cC7941FF199e540")
      .call({}, function (e, res) {
        setBurnegv2(noround(web3.utils.fromWei(res), 3));
      });
    await wbnbcontractInstance.methods
      .balanceOf("0xc2Cd8AC581Ff067cA99F1be53cC7941FF199e540")
      .call({}, function (e, res) {
        console.log("egold -- ", res);
        setBurnwbnb(noround(parseFloat(web3.utils.fromWei(res)), 3));
      });

    await busdcontractInstance.methods
      .balanceOf("0xc2Cd8AC581Ff067cA99F1be53cC7941FF199e540")
      .call({}, function (e, res) {
        console.log("egold -- ", res);
        setBurnbusd(noround(parseFloat(web3.utils.fromWei(res)), 2));
      });

    //fetch sales burn
    fetch(salerburn)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setSalerburn({ data: newData.total_egold_burned, loading: false });
      })
      .catch(function (error) {
        console.log(error);
      });

    //fetch total stake
    fetch(totalstakeurl)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setStakeTotal(newData.totalStaked);
      })
      .catch(function (error) {
        console.log(error);
      });

    //MiningStats
    fetch(urlMiningStats)
      .then(function (responseStats) {
        return responseStats.json();
      })
      .then(function (newStats) {
        setMiningStats({ data: newStats, loading: false });
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });

    await farmInstance.methods.paidOut().call({}, async function (e, res) {
      setMintedTotal(
        parseFloat(parseFloat(web3.utils.fromWei(res)).toFixed(2))
      );
    });
    await farmInstance.methods
      .totalPendingLP()
      .call({}, async function (e, res) {
        setUnclaimedTotal(
          parseFloat(parseFloat(web3.utils.fromWei(res)).toFixed(2))
        );
      });

    await pancakeFactorycontractInstance.methods
      .getPair(
        process.env.REACT_APP_USDT_ADDR,
        process.env.REACT_APP_EGOLDV2_ADDR
      )
      .call({}, function (e, res) {
        const LQInstance = new web3.eth.Contract(LIQUIDITY_ABI, res);
        console.log("RESPONSE LQ ", LQInstance);
        LQInstance.methods.getReserves().call({}, function (e, reserve) {
          console.log("reeserves");
          setliquidityEGOLDv2Blnce(
            parseFloat(web3.utils.fromWei(reserve[1])).toFixed(3)
          );
        });
      });

    await pancakeFactorycontractInstance.methods
      .getPair(
        process.env.REACT_APP_BUSD_ADDR,
        process.env.REACT_APP_EGOLD_ADDR
      )
      .call({}, function (e, res) {
        const LQInstance = new web3.eth.Contract(LIQUIDITY_ABI, res);
        console.log("RESPONSE LQ ", LQInstance);
        LQInstance.methods.getReserves().call({}, function (e, reserve) {
          console.log("reeserves");
          setliquidityBUSDBlnce(
            parseFloat(web3.utils.fromWei(reserve[1]) * 2).toFixed(3)
          );
          setliquidityEGOLDBlnce(
            parseFloat(web3.utils.fromWei(reserve[0])).toFixed(3)
          );
        });
      });

    const depositLPTInstance = await new web3.eth.Contract(
      dataVal.lpfarmabi,
      process.env.REACT_APP_BUSD_EGOLD_FARM
    );

    await depositLPTInstance.methods
      .paidOut()
      .call({}, async function (e, res) {
        setFarmYield(parseFloat(web3.utils.fromWei(res)));
      });

    await depositLPTInstance.methods
      .startBlock()
      .call({}, async function (e, res) {
        const currentbk = await web3.eth.getBlockNumber();
        setTB(currentbk - res);
      });

    await depositLPTInstance.methods
      .rewardPerBlock()
      .call({}, async function (e, res) {
        setFarmRPB(parseFloat(web3.utils.fromWei(res)));
      });
  };

  function noround(number, decimalDigits) {
    const powerOfTen = Math.pow(10, decimalDigits);
    const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
    if (Number.isInteger(formattedNumber)) {
      return roundzeroes(formattedNumber, 2);
    } else return formattedNumber;
  }
  function roundzeroes(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }

  return (
    <>
      {data.length == 0 ? (
        <div style={{ padding: "20px", textAlign: "center" }}>
          <Spinner />
        </div>
      ) : (
        <div className="chartbx">
          <div class="miningcapacity" id="miningCapacitySection">
            <div class="detailCard secpadding mb20">
              <div class="bardelshowp mb10" style={{backgroundColor:"cornsilk"}}>
                Egold 供应量 : 21,000,000 EGOLD
              </div>
              <div class="bardelshowp mb10 frstrow">
                Egold 锁定 (挖矿) : 20,000,000 EGOLD
              </div>
              <div class="bardelshowp mb10 frstrow">
              已挖矿 :{" "}
                {mintedtotal == "..." || unclaimedtotal == "..."
                  ? "..."
                  : parseFloat(
                      parseFloat(mintedtotal + unclaimedtotal).toFixed(2)
                    ).toLocaleString()}{" "}
                EGOLD
              </div>
              <div class="bardelshowp mb10 frstrow">
              已认领 : {mintedtotal.toLocaleString()} EGOLD
              </div>
              <div class="bardelshowp mb10 frstrow">
              已燃烧 (采矿燃料) :{" "}
                <div className="livetokegap">
                  <a
                    href="https://bscscan.com/token/0x8005d97e993668a528008d16338b42f9e976ed0f?a=0x0000000000000000000000000000000000000000"
                    target="_blank"
                    style={{ textDecoration: "none", color: "#c12222" }}
                  >
                    {mintedtotal == "..."
                      ? mintedtotal
                      : (mintedtotal / 2).toLocaleString()}{" "}
                  </a>
                  <span style={{ color: "red" }}>EGOLD</span>
                </div>
              </div>
              <div class="bardelshowp mb10 frstrow">
              已燃烧 (折旧) :{" "}
                <div className="livetokegap">
                  <a
                    href="https://bscscan.com/token/0x8005d97e993668a528008d16338b42f9e976ed0f?a=0x0000000000000000000000000000000000000000"
                    target="_blank"
                    style={{ textDecoration: "none", color: "#c12222" }}
                  >
                    {miningStats.data == "..." || salerburn.loading
                      ? "..."
                      : parseFloat(
                          parseFloat(
                            miningStats.data.egoldSupply.burnt -
                              mintedtotal / 2 -
                              salerburn.data
                          ).toFixed(1)
                        ).toLocaleString()}{" "}
                  </a>
                  <span style={{ color: "red" }}>EGOLD</span>
                </div>
              </div>
              <div class="bardelshowp mb10 frstrow">
              净认领 :{" "}
                {mintedtotal == "..." || miningStats.data == "..."
                  ? "..."
                  : parseFloat(
                      parseFloat(
                        mintedtotal - miningStats.data.egoldSupply.burnt
                      ).toFixed(1)
                    ).toLocaleString()}{" "}
                EGOLD
              </div>
              <div class="bardelshowp mb10 scndrow">
                Egold 锁定 (FARM币流动性提供者) : 180,000 EGOLD
              </div>
              <div class="bardelshowp mb10 scndrow">
                Egold 养殖 :{" "}
                {parseFloat(
                  parseFloat(farmrpb * totalblocks).toFixed(2)
                ).toLocaleString()}{" "}
                EGOLD
              </div>
              <div class="bardelshowp mb10 scndrow">
                Egold 已经认领 (LP Farm) :{" "}
                {parseFloat(parseFloat(farmyield).toFixed(2)).toLocaleString()}{" "}
                EGOLD
              </div>
              <div class="bardelshowp mb10 brnrow">
              矿工销售燃烧 :{" "}
                <div className="livetokegap">
                  <a
                    href="https://bscscan.com/token/0x8005d97e993668a528008d16338b42f9e976ed0f?a=0x0000000000000000000000000000000000000000"
                    target="_blank"
                    style={{ textDecoration: "none", color: "#c12222" }}
                  >
                    {salerburn.loading ? "..." : noround(salerburn.data, 3)}
                  </a>
                  <span style={{ color: "red" }}>EGOLD</span>
                </div>
              </div>
              <div class="bardelshowp mb10 brnrow">
              待刻录 ( EGOLD V1 ) :{" "}
                <span style={{ color: "red" }}>{burneg} EGOLD</span>
              </div>
              <div class="bardelshowp mb10 brnrow">
                待刻录 ( EGOLD V2 ) :{" "}
                <span style={{ color: "red" }}>{burnegv2} EGOLD</span>
              </div>
              <div class="bardelshowp mb10 brnrow">
                待刻录 ( WBNB ) :{" "}
                <span style={{ color: "red" }}>{burnwbnb} WBNB</span>
              </div>
              <div class="bardelshowp mb10 brnrow">
                待刻录 ( BUSD ) :{" "}
                <span style={{ color: "red" }}>{burnbusd} BUSD</span>
              </div>
              <div class="bardelshowp mb10 brnrow">第三方刻录 : 0 EGOLD</div>
              <div class="bardelshowp mb10 oalrow">
              其他分配 : 820,000 EGOLD
              </div>
              <div class="bardelshowp mb10 lkdrow">
              Egold分布式 :{" "}
                {farmyield == "..." ||
                mintedtotal == "..." ||
                miningStats.data == "..." ||
                salerburn.loading
                  ? "..."
                  : parseFloat(
                      parseFloat(
                        Number(
                          farmyield +
                            15000 +
                            (mintedtotal - miningStats.data.egoldSupply.burnt) -
                            noround(salerburn.data, 3)
                        )
                      ).toFixed(2)
                    ).toLocaleString()}{" "}
                EGOLD
              </div>
              <div class="bardelshowp mb10 lkdrow">
              锁定流动性 :{" "}
                {liquidityEGOLDBlnce == "..." || liquidityEGOLDv2Blnce == "..."
                  ? "..."
                  : parseFloat(
                      parseFloat(liquidityEGOLDBlnce) +
                        parseFloat(liquidityEGOLDv2Blnce)
                    ).toLocaleString()}{" "}
                EGOLD
              </div>
              <div class="bardelshowp mb10 lkdrow">
              锁定权益质押 :{" "}
                {staketotal == "..."
                  ? "..."
                  : parseFloat(staketotal).toLocaleString()}{" "}
                EGOLD
              </div>
              <div class="bardelshowp mb10 lkdrow">
              用户持有者 :{" "}
                {farmyield == "..." ||
                mintedtotal == "..." ||
                miningStats.data == "..." ||
                liquidityEGOLDBlnce == "..." ||
                liquidityEGOLDv2Blnce == "..." ||
                staketotal == "..." ||
                salerburn.loading
                  ? "..."
                  : parseFloat(
                      parseFloat(
                        Number(
                          farmyield +
                            15000 +
                            (mintedtotal - miningStats.data.egoldSupply.burnt) -
                            noround(salerburn.data, 3) -
                            parseFloat(liquidityEGOLDBlnce) -
                            parseFloat(liquidityEGOLDv2Blnce) -
                            staketotal
                        )
                      ).toFixed(2)
                    ) > 0
                  ? parseFloat(
                      parseFloat(
                        Number(
                          farmyield +
                            15000 +
                            (mintedtotal - miningStats.data.egoldSupply.burnt) -
                            noround(salerburn.data, 3) -
                            parseFloat(liquidityEGOLDBlnce) -
                            parseFloat(liquidityEGOLDv2Blnce) -
                            staketotal
                        )
                      ).toFixed(2)
                    ).toLocaleString()
                  : 0}{" "}
                EGOLD
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default TotaleSupply;
